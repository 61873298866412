.__order-parent {
    text-align: center;
    margin: 30px;
}

.__order-title {
    margin-bottom: 20px;
}

.__order-text {
    color: var(--font-primary-color);
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 20px;
}

.__order-text-failed {
    font-size: 18px;
    line-height: 30px;
    width: 70%;
    margin: 20px auto 20px auto;
}

.__order-button {
    width: 200px;
    height: 44px;
    margin: auto;
}

/* Order Success */

.__order-success-card-paper {
    padding: 10px;
    min-width: 400px;
    width: fit-content;
    max-width: 80%;
    margin: 20px auto 20px auto;
}

.__order-success-card-parent {
    font-size: 18px;
    display: flex;
    flex-direction: column;
}

.__order-success-card-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
}

.__order-success-card-value {
    color: var(--font-primary-color);
    margin-left: 10px;
}

.__order-success-card-divider {
    margin: 10px auto 10px auto;
    width: 80%;
    height: 1px;
    background-color: black;
    border: none;
}

.__order-success-card-expand {
    display: flex;
    width: 100%;
    margin-left: 10px;
    flex-direction: column;
}

.__order-success-card-expand-line {
    display: flex;
    width: 100%;
    max-width: 600px;
    text-align: justify;
    color: var(--font-primary-color);
}

.__order-success-card-expand-value {
    margin-left: 10px;
}

.__cart-price {
    font-size: 22px;
    color: var(--font-title-color);
}

.__order-mylessons-button {
    max-width: 300px;
    width: fit-content;
    padding: 10px;
    margin: auto;
}