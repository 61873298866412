/* Title */

.__title,
.__title h2 {
  font-family: 'Montserrat';
  font-size: 22px;
  color: var(--font-title-color);
  font-weight: 100;
}

.__signup-parent .__title h2 {
    color: var(--font-title2-color);
}

/* Textfield */

.__textfield-parent {
    height: 42px;
    width: 100%;
}

.__textfield-content-error,
.__textfield-content {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: var(--basic-border-radius);
    overflow: hidden;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
}

.__textfield-content-error {
    border-color: var(--error-color) !important;
}

.__textfield-content:focus-within {
    border-color: var(--primary-color);
}

.__textfield-input-no-svg,
.__textfield-input,
.__textfield-textarea-no-svg,
.__textfield-textarea {
    border: none;
    padding: auto 15px;
  	font-size: 16px;
    color: var(--font-input-color)!important;
    background-color: var(--font-input-color2)!important;
  	outline: none;
    line-height: 1.5;
}

::placeholder {
    color: var(--font-input-color)!important;
    opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--font-input-color)!important;
    opacity: 0.5;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--font-input-color)!important;
    opacity: 0.5;
}

.__textfield-input {
    width: calc(100% - 40px);
}

.__textfield-input-no-svg {
  width: 100%;
}

.__textfield-textarea {
  resize: none;
  width: calc(100% - 40px);
  padding: 10px;
}

.__textfield-textarea-no-svg {
  resize: none;
  width: 100%;
  padding: 10px;
}

.__textfield-icon-container {
    position: relative;
    height: 40px;
    width: 40px;
    float: right;
}

.__textfield-icon-image {
    margin: 8px;
    width: 24px;
    height: 24px;
    fill: var(--border-color);
}

.__textfield-icon-button-image {
    cursor: pointer;
}

.__textfield-icon-button-image:hover {
    fill: var(--primary-color);
}

.__textfield-error {
    margin-top: 0px;
    /* height: 18px; */
    font-size: 12px;
    color: var(--error-color);
    font-style: italic;
    padding-left: 5px;
}

/* Numberfield */

.__numberfield-input:hover::-webkit-inner-spin-button {
    font-size: 50px;
}

/* Button */

.__button {
    background-color: var(--button-color);
    color: var(--font-quaternary-color);
    width: 100%;
    height: 100%;
    border-radius: var(--basic-border-radius);
    border: none;
    margin-top: 0px;
    font-size: 16px;
    align-self: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s;
}

.__button:disabled {
    background-color: var(--disabled-color);
    color: var(--ternary-color);
    cursor: not-allowed;
}

.__button:hover:enabled {
    filter: brightness(110%);
}

.__button-container {
    width: 100%;
    height: 100%;
}

.__button-loading {
    margin-right: 10px;
    height: 0px;
}

.__button-loading > .circular-progress {
    margin: 0px;
}

/* DatePickerField */

.__datepicker-input {
    width: 100%;
}

.__datepicker-container {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: var(--basic-border-radius);
    overflow: hidden;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
}

.__expandable-header .__datepicker-container {
    border-color:  var(--border-color-inverse);
    background-color: var(--font-input-color-inverse);

}

.__datepicker-container:focus-within {
    border-color: var(--primary-color);
}

/* SelectField */

.__select-parent {
    width: 100%;
    height: 100%;
    font-size: 16px;
    position: relative;
    border-radius: var(--basic-border-radius);
    overflow: hidden;
    border: 1px solid var(--ternary-color);
}

.MuiPickersModal-dialogRootWider {
    min-width: 360px!important;
}

.MuiPickersModal-dialogRootWider .MuiPickersBasePicker-pickerView {
    max-width: 360px!important;
}

.MuiSelect-icon {
    top: initial!important;
}

.__select-parent-school .__select-selector {
    padding: 10px 20px 10px 10px;
}

.__select-selector {
    /* width: 100%; */
    height: 100%;
    padding: 10px 0px 10px 10px !important;
}

.__select-selector>div {
    padding: initial !important;
    padding-right: 40px !important;
}

.__select-selector option {
    font-size: 16px;
    /* background-color: var(--quaternary-color)!important; */
    background-color: var(--select-options-color)!important;
}

.__select-parent:focus-within {
    border-color: var(--primary-color);
}
.__select-item {
    font-size: 16px;
}

/* Checkbox */

.__checkbox-parent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.__checkbox-parent-disabled > label,
.__checkbox-parent-disabled {
    color: var(--font-primary-color);
    cursor: default;
}

.__checkbox-label {
    color: var(--font-ternary-color);
    cursor: pointer;
}

.__checkbox-svg {
    height: 24px;
    width: 24px;
}

/* .__checkbox-svg rect{
    fill: var(--checkbox-color);
} */

.__checkbox-svg-disabled rect {
    fill: var(--primary-color);
}

.__checkbox-svg > div {
    height: 24px;
    width: 24px;
}

.__checkbox-input {
    visibility: hidden;
}