.__signup-parent {
    background-color: var(--background-modal-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    padding-top: 30px;
    padding-bottom: 20px;
    min-width: 300px;
    border-radius: var(--basic-border-radius);
}

.__signup-header {
    margin: 20px 20px 0px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__signup-header-text {
    font-family: 'Montserrat';
    font-size: 22px;
    color: var(--font-primary-color);
    margin-top: 20px;
}

.__signup-header-close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    fill: var(--ternary-color)!important;
}

.__signup-header-close:hover {
    fill: var(--primary-color)!important;
}

.__signup-form {
    display: flex;
    justify-content: space-between;
    background-color: var(--background-modal-color);
    flex-direction: column;
    align-items: center;
    min-width: 500px;
    margin-bottom: 10px;
}

.__signup-form-field {
    margin: 8px;
    width: 70%;
}

.__signup-form-header {
    font-size: 14px;
}

.__signup-form-field input {
    margin-top: 8px;
    font-size: 12px;
}

.__signup-form-field-input-valid {
    width: 100%;
}

.__signup-form-field-input-error {
    background-color: lightpink;
    border-color: var(--error-color);
    width: 100%;
}

.__signup-form-field-error-container {
    display: flex;
    align-items: flex-start;
}

.__signup-form-field-error-text {
    font-size: 10px;
    color: var(--error-color);
    margin-top: 4px;
    margin-bottom: 6px;
}

.__signup-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__signup-footer-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__signup-login-text{
    color: var(--font-primary-color)!important;
    text-align: center;
}

.__signup-login-forgot {
    color:  var(--primary-color);
    margin: 5px 10px 5px 5px;
    cursor: pointer;
}

.__signup-login-button{
    color:  var(--primary-color);
    font-weight: bold;
    margin: 5px 0px 5px 5px;
    cursor: pointer;
    display: inline;
}

.__signup-divider {
    width: 80%;
    margin-top: 15px;
}

.__newsflash-divider {
    margin-top: 15px;
    margin-bottom: 15px;
}

.__signup-divider-bottom {
    width: 80%;
    margin-bottom: 15px;
}

.__signup-error {
    color: var(--error-color);
}

.__signup-content {
    margin: 20px 0px 0px 0px;
}

.__signup-textfield-container {
    width: 80%;
    height: 100%;
    margin: 10px;
    padding-right: 20px;
    padding-left: 20px;
}

.__signup-button-container{
    height: 40px;
    width: 40%;
    padding-right: 20px;
    padding-left: 20px;
    margin: 10px;
}

.__signup-forgot-message-text {
    color: var(--font-primary-color);
    margin: 20px 0px 40px 0px;
    width: 400px;
    font-size: 16px;
    line-height: 24px;
}

.__signup-forgot-message-button {
    height: 40px !important;
    width: 100px !important;
}

/* Smartphone */

@media only screen and (max-width: 500px) {
    .__signup-parent {
        top: 0px;
        left: 0px;
        transform: translate(0,0);
        width: 100%;
        height: 100%;
        justify-content: space-between;
    }

    .__signup-form {
        min-width: 100%;
    }

    .__signup-login-text {
        bottom: 40px;
    }

    .__signup-textfield-container {
        width: 100%;
        height: 100%;
        margin: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }

    .__signup-button-container {
        margin: 10px;
        padding-right: 10px;
        padding-left: 10px;
        width: 60%;
        min-width: 180px;
    }
}

@media only screen and (min-width: 500px) and (max-width: 900px) {
    .__signup-parent {
        max-height: 95%;
        padding: 10px 0px 10px 0px;
    }

    .__signup-header {
        margin: 5px 0px 15px 0px;
    }

    .__signup-content {
        overflow: scroll;
        margin-top: 5px;
    }
}
