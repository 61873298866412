.__nomatch-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.__nomatch-title {
    margin: 40px;
}

.__nomatch-text {
    margin: 40px;
}