.__expandable-parent {
    border: 1px solid var(--expandable-header-color);
    /* border-radius: var(--basic-border-radius); */
}



.__expandable-header {
    background-color: var(--expandable-header-color);
    cursor: pointer;
    min-height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-size: 22px;
    position: relative;
}

.__expandable-title {
    color: var(--font-ternary-color-inverse);
    margin: auto 20px;
}

.__expandable-icon {
    /* color: var(--font-primary-color); */
    color: var(--font-primary-color-inverse);
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.__expandable-elements-container {
    height: 80%;
    max-width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
}

.__expandable-element {
    min-width: 0; /* Important pour que l'enfant ne dépasse pas la largeur de la colonne */
    max-width: 100%; /* Limiter la largeur à la taille de la colonne */
    overflow: hidden; /* Masquer le contenu qui dépasse */
    text-overflow: ellipsis; /* Ajouter des "..." pour les textes longs */
    white-space: nowrap; 
}

@media only screen and (max-width: 900px) {

    .__expandable-title {
        color: var(--font-title-color);
    }
    
    .__expandable-icon {
        color: var(--font-title-color);
    }

    .__expandable-elements-container {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .__expandable-element {
        width: 100%;
    }

    .__expandable-title-with-elements {
        margin: 10px auto 10px auto;
    }

    .__expandable-header-with-elements {
        flex-direction: column;
    }

    .__expandable-icon-with-elements {
        position: absolute;
        right: 20px;
        top: 15px;
    }
}