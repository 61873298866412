.__cart-parent {
    font-size: 16px;
}

.__cart-no-items-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.__cart-no-items-button {
    width: 200px;
    padding: 10px;
}

.__cart-no-items-button-container {
    margin: auto;
    width: 200px;
    margin-bottom: 55px;
}

.__cart-no-items {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.__cart-important-parent {
    font-size: 16px;
    margin: 10px 0px 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.__cart-important-primary {
    color: var(--secondary-color);
    line-height: 30px;
}

.__cart-important-info {
    color: var(--secondary-color);
    font-weight: bold;
    line-height: 50px;
}

.__cart-important-secondary {
    color: var(--ternary-color);
    line-height: 25px;
}

.__cart-footer {
    margin: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.__cart-footer-checkboxes {
    display: flex;
}

.__cart-footer-bottom {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
}

.__cart-footer-bottom-left {
    display: flex;
    flex-direction: column;
}

.__cart-payment-cards-title {
    color: var(--font-primary-color);
    font-size: 20px;
}

.__cart-payment-cards {
    margin: 20px 0px 0px 0px;
    height: 50px;
}

.__cart-footer-bottom-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.__cart-footer-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.__cart-price-subtotal {
    color: var(--primary-color);
    font-size: 18px;
    margin: 10px 0px 10px 0px;
}

.__cart-price-total {
    color: var(--primary-color);
    font-size: 20px;
    margin: 10px 0px 10px 0px;
}

.__cart-discount {
    color: var(--secondary-color);
    font-size: 16px;
    margin: 10px 0px 10px 0px;
}

.__cart-button {
    margin: 20px 0px 0px 0px;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.__cart-button-pay {
    min-width: 200px;
    padding: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.__cart-button-discount-modal {
    min-width: 200px;
    padding: 10px;

}

.__cart-button-continue {
    min-width: 200px;
    padding: 10px;
    margin-right: 20px;
}

.__cart-button-continue-container {
    margin-right: 10px;
}

.__cart-code-discount {
    max-width: 200px;
}


.__use-discount-content {
    display: flex;
    flex-direction: column;
    min-width: 260px;
}

.__cart-code-discount-red{
    animation: shake_error 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    border: 1px solid var(--secondary-color)!important;
}

.__cga-toline {
    display: block;
}

.__cart-button-apply-code-discount {
    height: 39px;
    padding-left: 10px;
    padding-right: 10px;
}

.__cart-code-discount-container {
    height: 39px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.__cart-checkbox-label {
    font-size: 16px;
}

/* Modal */

.__cart-dialog {
    background-color: transparent;
    width: fit-content;
    height: fit-content;
    padding: 10px;
}

.__cart-dialog-text {
    color: var(--font-primary-color);
    font-size: 16px;
    margin: 20px 0px 20px 0px;
}

.__cart-dialog-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.__cart-dialog-button-container {
    width: fit-content;
}

.__cart-dialog-button,
.__cart-dialog-button-red {
    display: flex;
    justify-content: center;
    height: 40px;
    width: 100px;
}

.__cart-dialog-button-red {
    background-color: var(--error-color);
}

/* CartLesson */

.__cart-lesson-disabled-element {
    opacity: 0.2;
}

.__cart-lesson-location {
    font-weight: bold;
}

.__cart-lesson-button {
    background-color: var(--error-color);
}

.__cart-lesson-participants-visibility-grey,
.__cart-lesson-participants-visibility {
    display: inline-flex;
    margin-left: 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.__cart-lesson-participants-visibility-grey svg,
.__cart-lesson-participants-visibility svg {
    fill: var(--ternary-color);;
    width: 20px;
    height: 20px;
}

.__cart-lesson-participants-visibility-grey {
    fill: var(--ternary-color);
}

.__cart-lesson-participants-visibility-grey:hover,
.__cart-lesson-participants-visibility:hover {
    fill: var(--primary-color);
}

.__cart-lesson-participants-plus {
    display: inline;
    cursor: pointer;
    font-size: 24px;
    margin-left: 10px;
    margin-right: -20px;
    color: var(--font-primary-color);
}

.__cart-lesson-expand {
    margin: 10px;
    max-width: 280px;
}

.__cart-lesson-expand-line {
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--ternary-color);
    word-break: break-word;
    max-width: 250px;
}

.__cart-lesson-expand-value {
    display: inline;
    margin-right: 10px; 
    font-weight: bold;
}

.__cart-lesson-expand-key {
    display: inline;
    color: var(--font-primary-color);
    margin-right: 10px;
}

/* Settings Modal */

.__cart-settings-content {
    margin-bottom: 30px;
}

.__cart-settings-title {
    margin-bottom: 20px;
}

.__discount-pick-discount-type {
    background-color: var(--expandable-header-color)!important;
    border-top-left-radius: var(--basic-border-radius);
    border-top-right-radius: var(--basic-border-radius);
}

.__discount-pick-discount-type .MuiBottomNavigationAction-root.Mui-selected {
    color: var(--primary-color);
}

.__discount-pick-discount-type.MuiBottomNavigation-root {
    height: 60px;
}

.__discount-pick-discount-type .__discount-code-discount-icon svg {
    width: 28px;
    height: 28px;
}


.__discount-content-field {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 42px;
    padding: 15px 25px;
    background-color: rgb(from var(--expandable-header-color) r g b / 55%) !important;
    border-bottom-left-radius: var(--basic-border-radius);
    border-bottom-right-radius: var(--basic-border-radius);
}

.__discount-content-field .__checkbox-parent {
    height: 42px;
}

.__discount-content-field .__textfield-content,
.__discount-content-field .__checkbox-svg {
    background-color: var(--background-modal-color);
}

.__button-group {
    margin-bottom: 20px;
}

.__button-group .MuiButton-root {
    border-radius: var(--basic-border-radius);
    text-transform: initial;
}

.__button-group .MuiButton-outlinedPrimary {
    /* background-color: var(--button-color); */
    color: var(--font-sitemap-color);
    border: 1px solid var(--button-color);

}
.__button-group .__button-group-button-selected,
.__button-group .MuiButton-outlinedPrimary:hover {
    border: 1px solid var(--button-color);    
    color: var(--font-quaternary-color);
    background-color: var(--button-color);
}

.__button-group-button  {
    font-size: 16px!important;
}

.__cart-settings-bottom {
    height: 44px;
    display: flex;
    align-items: center;
    /* position: sticky;
    bottom: 0px; */
}

/* .__cart-settings-bottom-background {
    width: calc(100% + 40px);
    height: 88px;
    background-color: var(--background-secondary-color);
    filter: blur(10px);
    position: absolute;
    left: -20px;
    z-index: -1;
} */

.__cart-settings-buttons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.__cart-settings-cancel {
    background-color: var(--primary-color);
}

.__cart-settings-button-container {
    width: calc(50% - 10px);
}

.__cart-points-checkbox {
    margin-top: 20px;
}

.__modal-parent.__discount-modal {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    max-width: 100%;
}

@media only screen and (max-width: 500px) {
    .__cga-toline {
        display: inline;
    }

    .__use-discount-content {
        display: flex;
        flex-direction: column;
        min-width: 100%;
    }

    .__discount-modal {
        width: 100%;
        margin-left: 13px;
        margin-right: 13px;
    }

    .__cart-footer-bottom-left {
        width: 100%;
    }

    .__cart-important-parent {
        margin: 10px 0px 20px 0px;
    }

    .__cart-lesson-paper {
        margin: 20px 0px 20px 0px;
    }

    .__cart-parent {
        font-size: 16px;
        font-family: 'Montserrat';
        margin: 0px 10px 0px 10px;
    }

    .__cart-no-items {
        text-align: justify;
    }

    .__cart-footer {
        margin: 0px 0px 0px 0px;
    }

    .__cart-footer-checkbox {
        margin: 10px 0px 10px 0px;
    }
    
    .__cart-footer-bottom {
        margin: 0px 0px 10px 0px;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }

    .__cart-footer-bottom-left {
        margin: 0px 0px 10px 0px;
    }

    .__cart-payment-cards {
        width: 100%;
        height: auto;
    }
    
    .__cart-buttons-container {
        flex-direction: column;
        width: 100%;
    }
    
    .__cart-button {
        margin: 10px 0px 10px 0px;
        width: 100%;
        flex-direction: column;
    }
    
    .__cart-button-home {
        padding: 10px;
    }

    .__cart-button-continue {
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
    }

    .__cart-button-discount-modal {
        width: 100%;
        margin-bottom: 10px;    
    }
    
    .__cart-button-pay {
        padding: 10px;
        width: 100%;
    }

    .__cart-lesson-participants-plus {
        margin-right: 0px;
    }
}

@keyframes shake_error {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}