.__bottombar-parent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background-color: var(--background-secondary-color);
    width: 100%;
    padding: 10px
}

.__bottombar-parent-desktop {
    display: none;
}

.__bottombar-parent-mobile {
    display: flex;
    flex-direction: row;
}

.__bottombar-item-parent {
    display: flex;
    flex-direction: column;
    padding:10px;
}

.__bottombar-item-icon {
    width:20px;
    height: 20px;
    margin:5px;
    align-self: center;
}

.__bottombar-item-title {
    font-size: 14px;
    align-self: center;
    margin-bottom: 5px;
}


@media only screen and (min-width: 992px) {
    /*.__bottombar-parent {*/
        /*display: flex;*/
        /*position: absolute;*/
        /*flex-direction: row;*/
        /*justify-content: space-between;*/
        /*align-items: flex-start;*/
        /*height: 100%;*/
        /*width: 240px;*/
        /*padding: 10px;*/
        /*margin-top: 80px;*/
        /*align-self: flex-start;*/
        /*background-color: white;*/
    /*}*/

    /*.__bottombar-parent-mobile {*/
        /*display: none;*/
    /*}*/

    /*.__bottombar-parent-desktop {*/
        /*display: flex;*/
        /*flex-direction: column;*/
    /*}*/

    /*.__bottombar-item-parent {*/
        /*display: flex;*/
        /*flex-direction: row;*/
        /*padding:10px;*/
    /*}*/

    /*.__bottombar-item-title {*/
        /*font-size: 14px;*/
        /*margin-left:5px;*/
    /*}*/


}
