.__sidebar-parent {
    background-color: var(--background-primary-color);
    position: relative;
    width: 100%;
    height: 100%;
}

.__sidebar-ul {
    list-style: none;
    padding-top: 20px;
    text-align: center;
    position: sticky;
    top: 0;
}

.__sidebar-item-li {
    display: flex;
    justify-content: space-between;
    width: 170px;
    padding: 10px 5px 10px 5px;
    cursor: pointer;
    margin: auto;
    align-items: center;
}

.__sidebar-item-img {
    width:30px;
    height:30px;
}

.__sidebar-item-img > div {
    height: 30px;
    width: 30px;
}

.__sidebar-item-li:hover > .__sidebar-item-text {
    color: var(--primary-color) !important;
}

.__sidebar-item-li:hover > .__sidebar-item-img {
    fill: var(--primary-color) !important;
}

.__sidebar-item-li:hover .__sidebar-item-img polygon,
.__sidebar-item-li:hover .__sidebar-item-img line,
.__sidebar-item-li:hover .__sidebar-item-img circle {
    stroke: var(--primary-color);
}

.__sidebar-item-text {
    font-size: 14px;
    padding-top: 5px;
}

@media only screen and (max-width: 900px) {

    .__sidebar-parent {
        background-color: var(--background-secondary-color);
    }

    .__sidebar-item-li {
        margin: 0px 20px 10px 20px;
        padding: 5px;
    }

    .__sidebar-ul {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}