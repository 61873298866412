.__booking-parent {
    display: flex;
    width: 100%;
    height: 100%;
}

.__booking-left-small {
    width: calc(100% - 340px);
}

.__booking-left-big {
    width: 100%;
}

.__booking-right-small {
    margin: 0px -8px -8px 0px;
    min-width: 340px;
}

.__booking-right-big {
    margin: 0px -8px -8px 0px;
}

.__booking-left-no-lesson {
    display: flex;
    justify-content: center;
    margin: 30px 10px 30px 10px;
}

.__booking-left-no-lesson .__title {
    font-size: 20px;
}

.__booking-more-lessons {
    margin: 20px auto 20px auto;
    width: fit-content;
}

.__booking-more-lessons-button {
    padding: 10px;
}

.__booking-i {
    cursor: pointer;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),
        0px 1px 1px 0px rgba(0,0,0,0.14),
        0px 2px 1px -1px rgba(0,0,0,0.12);
    border-radius: 100%;
    color: var(--primary-color);
    margin-left: 6px;
}

.__booking-i-sp {
    background-color: transparent;
}

.__booking-tooltip {
    cursor: pointer;
}

/* BookingHeader */

.__booking-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px 20px 20px 20px;
}

.__booking-header-title {
    color: var(--primary-color);
    margin-bottom: 10px;
}

.__booking-header-content {
    display: flex;
    font-size: 16px;
}

.__booking-header-content-text {
    min-width: 300px;
    max-width: 100%;
    width: fit-content;
    white-space: nowrap;
    margin-right: 30px;
    line-height: 30px;
}

.__booking-header-content-info {
    display: flex;
}

.__booking-header-content-description {
    padding-top: 5px;
    width: 100%;
}

.__booking-header-text-description {
    color: var(--ternary-color);
    text-align: justify;
    line-break: auto;
}

.__products-card-text h2,
.__booking-header-text-description h2 {
    /* font-family: 'Montserrat'; */
    font-size: 18px;
    color: var(--font-title-color);
    font-weight: 100;
    margin-bottom: 10px;
}

.__booking-header-text-description li {
    margin-left: 25px;
}

.__booking-header-text-description ul {
    margin-bottom: 10px;
}

.__booking-header-text-description p {
    margin: 0px 0px 10px 0px;
}

.__booking-header-text-description hr {
    opacity: 0.3;
    margin: 10px 0px 10px 0px;
}

.__products-card-text a {
    color: var(--primary-color);
}

.__booking-header-text {
    color: var(--ternary-color);
}

.__booking-header-category {
    color: var(--font-ternary-color);
    margin-right: 10px;
}

/* DetailBar */

.__booking-detailsbar-datepicker {
    min-width: 200px !important;
    width: 100% !important;
}

.__booking-detailsbar-select-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: var(--basic-border-radius);
    height: 44px;
    cursor: default;
}

.__expandable-header .__booking-detailsbar-select-parent {
    border-color: var(--border-color-inverse);
    background-color: var(--font-input-color-inverse);
}

.__booking-detailsbar-select-text {
    color: var(--font-ternary-color);
    font-size: 16px;
    margin: 0px 10px 0px 15px;
}

.__booking-detailsbar-select-participants {
    width: 50px !important;
    border: none !important;
}

.__booking-detailsbar-select-level {
    width: fit-content !important;
    max-width: 400px !important;
    border: none !important;
}

.__booking-detailsbar-select-input {
    padding: 0px 15px 0px 0px;
    width: 100%; /* Ou une largeur fixe si besoin */
    max-width: 100%; /* Empêche le dépassement */
    white-space: nowrap; /* Empêche le texte de passer à la ligne */
    overflow: hidden; /* Masque le texte qui dépasse */
    text-overflow: ellipsis;
}

.__booking-detailsbar-level-text {
    color: var(--font-ternary-color-inverse);
    font-size: 16px;
}

/* Lesson Details */

.__booking-lesson-paper {
    height: 100%;
    margin: 30px 20px 30px 20px;
}

.__booking-lesson-parent {
    background-color: var(--background-primary-color);
    border-radius: var(--basic-border-radius);
    height: 100%;
    width: 100%;
    font-size: 16px;
    padding: 20px;
}

.__booking-lesson-header {
    margin: 0px 0px 20px 0px;
}

.__booking-lesson-body {
    display: flex;
    justify-content: space-between;
}

.__booking-lesson-divider {
    width: 1px;
    border: none;
    background-color: var(--ternary-color);
    margin: 0px 10px 0px 10px;
}

.__booking-lesson-1st {
    margin: 0px;
    height: 100%;
}

.__booking-lesson-title {
    color: var(--primary-color);
    width: fit-content;
    height: 25px;
    font-size: 20px;
}

.__booking-lesson-title-number {
    font-weight: bold;
    margin-left: 10px;
}

.__booking-lesson-1st-info {
    margin: 15px 0px 15px 0px;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
}

.__booking-lesson-1st-info:first-child {
    margin: 0px 0px 15px 0px;
}

.__booking-lesson-1st-info:last-child {
    margin: 15px 0px 0px 0px;
}

.__booking-lesson-1st-text {
    color: var(--font-ternary-color);
    margin-right: 40px;
}

.__booking-lesson-1st-text-error {
    color: red;
    font-weight: bold;
}

.__booking-lesson-1st-value {
    font-weight: bold;
    color: var(--ternary-color);
    text-align: right;
    display: flex;
    align-items: center;
}

.__booking-lesson-level-container {
    display: flex;
    align-items: center;
}

.__booking-lesson-2nd {
    color: var(--font-ternary-color);
    margin: 0px;
    height: 100%;
}

.__booking-lesson-2nd-content {
    display: flex;
    justify-content: space-between;
    margin: 15px 0px 15px 0px;
    line-height: 30px;
}

.__booking-lesson-2nd-content:first-child {
    margin-top: 0px;
}

.__booking-lesson-2nd-content:last-child {
    margin-bottom: 0px;
}

.__booking-lesson-2nd-text {
    margin-right: 40px;
    align-self: center;
}

.__booking-lesson-2nd-fixed-value {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.__booking-lesson-3rd {
    align-self: flex-end;
    justify-self: flex-end;
    margin: 20px 0px 8px 20px;
}

.__booking-lesson-3rd-with-select {
    align-self: flex-end;
    justify-self: flex-end;
    margin: 20px 0px 0px 20px;
}

.__booking-lesson-3rd-price {
    color: var(--font-primary-color);
    font-size: 18px;
    text-align: center;
    margin: 20px 0px 20px 20px;
}

.__booking-lesson-3rd-button {
    height: 40px;
    min-width: 120px;
}

.__booking-lesson-3rd-button-loading {
    height: 40px;
    min-width: 150px;
}

.__booking-lesson-select-span {
    height: 30px;
}

.__booking-lesson-no-availability-snackbar-link:visited,
.__booking-lesson-no-availability-snackbar-link {
    color: var(--font-primary-color);
}

.__booking-lesson-warning {
    position: absolute;
    right: -40px;
    color: var(--error-color);
    opacity: 0.5;
}

/* Participants */

.__booking-options-parent,
.__booking-participants-parent {
    display: flex;
    gap: 10px;
    flex-direction: column;
    height: 100%;
    margin: 20px;
}

.__booking-participants-component {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 16px;
    padding: 15px 10px;
    background-color: var(--background-primary-color);
    border-radius: var(--basic-border-radius);
    justify-content: center;
}

.__booking-participants-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-radius: 5px;
}

.__booking-participants-title {
    color: var(--font-primary-color);
    font-size: 18px;
    margin-right: 20px;
}
.__booking-participants-select-must .__select-parent,
.__landing-search-bar-schools-error .__select-parent {
    border: 2px solid red !important;
}

.__landing-search-bar-schools-error .__select-parent {
    margin: -2px;
    width: calc(100% + 3px);
    height: calc(100% + 4px);
}



.__booking-participants-select {
    max-width: 40%;
}

.__booking-options-left,
.__booking-participants-fields {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}

.__booking-participants-fields-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.__booking-options-button,
.__booking-participants-button,
.__booking-questions-button {
    height: 40px;
    width: 120px;
    align-self: flex-end;
}
.__booking-questions-button {
    margin: 0px;
    float: right;
}

/* .__booking-questions-button {
    height: 40px;
} */

.__booking-participants-field {
    min-width: 250px;
    align-self: flex-end;
}

.__booking-participants-divider {
    width: 80%;
    height: 1px;
    border: none;
    background-color: var(--ternary-color);
    margin: 10px;
}

.__booking-participants-error {
    color: var(--error-color);
    font-size: 16px;
    margin: 0px 0px 20px 20px;
}

/* ParticipantField */

.__booking-participantField-textfield {
    height: auto;
}

.__booking-participantField-select {
    height: auto;
    min-width: 202px;
}

.__booking-participantField-numberfield-parent {
    font-size: 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.__booking-participantField-numberfield-text {
    width: 150px;
    margin: 0px 10px 0px 20px;
}

.__booking-participantField-numberfield {
    height: auto;
}


/* Options */

.__booking-options-left {
    margin: 20px;
    align-self: center;
}

.__booking-options-content {
    width: 100%;
    height: 100%;
}

.__grid_parent_question .MuiGrid-spacing-xs-2 {
    width: calc(100% + 30px)
}

.__booking-options-left-content {
    display: flex;
    align-items: center;
}

.__booking-options-checkbox {
    display: flex;
    width: calc(100% - 10px);
    margin: 10px 10px 10px 0px;
}

.__booking-options-checkbox-input {
    margin-left: 20px;
}

.__booking-options-checkbox-label {
    width: 100%;
}

.__booking-options-label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 30px;
}

.__booking-options-label-grey {
    color: var(--ternary-color);
}

.__booking-options-numberfield {
    width: 70px;
    margin-right: 20px;
}

/* Free Instructors */

.__booking-instructors-parent {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.__booking-instructors-left {
    display: flex;
    width: 100%;
}

.__booking-instructors-selects {
    width: calc(100% - 140px);
    margin: 10px 20px 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.__booking-instructors-button {
    height: 40px;
    width: 120px;
    align-self: flex-end;
}

.__booking-instructors-no-instructor {
    font-size: 16px;
    color: var(--font-primary-color);
}

.__booking-instructors-select-parent {
    height: 44px;
    width: 300px;
}

.__booking-instructors-select-parent:first-child {
    margin-bottom: 20px;
}

.__booking-instructor-error {
    color: var(--error-color);
    font-size: 16px;
    margin-top: 10px;
}

.__booking-instructors-checkboxes {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    height: 120px;
}

/* Remarks */

.__booking-remarks-parent {
    display: flex;
    flex-direction: column;
}

.__booking-remarks-textfield {
    margin: 20px;
}

.__booking-remarks-button-container {
    margin: 20px;
    width: calc(100% - 40px);
    display: flex;
    gap:10px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.__booking-remarks-button {
    background-color: var(--secondary-color);
    padding: 10px;
}

.__booking-remarks-button-container2 {
    width: fit-content!important;
}

/* Selection */

.__booking-selection-parent {
    margin-left: 20px;
    padding: 20px;
    height: 100%;
    width: 330px;
    background-color: var(--background-primary-color);
}

.__booking-selection-content {
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;
}

.__booking-selection-title {
    color: var(--primary-color);
    margin: 20px 0px 20px 0px;
    white-space: nowrap;
}

.__booking-selection-body {
    border: 1px solid var(--ternary-color);
    font-size: 16px;
    border-radius: var(--basic-border-radius);
    overflow: hidden;
}

.__booking-selection-divider {
    width: 80%;
    margin: 5px auto 5px auto;
    height: 1px;
    background-color: var(--ternary-color);
}

.__booking-selection-remarks,
.__booking-selection-text {
    color: var(--font-primary-color);
    display: flex;
    justify-content: space-between;
    margin: 20px 10px 20px 10px;
}

.__booking-selection-remarks {
    text-align: justify;
    max-width: 250px;
    word-break: break-word;
}

.__booking-selection-value {
    color: var(--ternary-color);
    margin-left: 10px;
    text-align: right;
}

.__booking-selection-footer {
    display: flex;
    justify-content: space-between;
}

.__booking-selection-expandable-container:first-child .__booking-selection-expandable {
    border-top-left-radius: var(--basic-border-radius);
    border-top-right-radius: var(--basic-border-radius);

}

.__booking-selection-expandable {
    font-size: 18px;
    min-height: 40px;
}

.__booking-selection-expandable-container {
    box-shadow: none;
}

.__booking-selection-expandable-container:first-child {
    margin-top: 0px;
}

.__booking-selection-expandable-container:last-child {
    margin-bottom: 0px;
}

/* Alert */

.__booking-alert-parent {
    background-color: var(--background-primary-color);
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__booking-alert-title {
    color: var(--error-color);
    font-size: 24px;
}

.__booking-alert-text {
    text-align: justify;
    line-break: strict;
    margin: 20px 0px 20px 0px;
    line-height: 24px;
    font-size: 16px;
}

.__booking-alert-school {
    font-weight: bold;
}

.__booking-alert-second {
    margin-top: 10px;
}

.__booking-alert-button-container {
    text-align: center;
}

.__booking-alert-button {
    height: 40px;
    width: 200px;
}

/* Smartphone */

.__booking-sp-parent {
    height: 40px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--quaternary-color);
    margin: 10px auto 10px auto;
    position: sticky;
    top: 10px;
    z-index: 10;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),
        0px 1px 1px 0px rgba(0,0,0,0.14),
        0px 2px 1px -1px rgba(0,0,0,0.12);
}
  
.__booking-sp-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: var(--font-primary-color);
}

.__booking-sp-svg {
    filter: var(--img-to-white);
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.__booking-detailsbar-expandable-container {
    border-top-right-radius: var(--basic-border-radius);
    border-top-left-radius: var(--basic-border-radius);
    overflow: hidden;
}

.__booking-detailsbar-expandable-container .__expandable-header  {
    border-top-right-radius: var(--basic-border-radius);
    border-top-left-radius: var(--basic-border-radius);

}

@media only screen and (max-width: 800px) {

    .__booking-header-content {
        flex-direction: column;
    }

    .__booking-header-content-info,
    .__booking-header-content-description {
        margin-top: 10px;
    }

    .__booking-detailsbar-expandable-container {
        box-shadow: none;
    }

    .__booking-lesson-paper {
        height: fit-content;
        margin: 20px 10px 20px 10px;
    }

    .__booking-lesson-parent {
        padding: 15px;
    }

    .__booking-lesson-header {
        margin: 0px 0px 20px 0px;
    }
    
    .__booking-lesson-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .__booking-lesson-title {
        font-size: 18px;
        margin-bottom: 0px;
        height: fit-content;
    }

    .__booking-lesson-divider {
        width: 100%;
        background-color: var(--ternary-color);
        margin: 10px 0px 10px 0px;
        align-self: center;
    }

    .__booking-lesson-1st {
        margin: 0px 0px 0px 0px;
    }

    .__booking-lesson-1st-info {
        margin: 20px 0px 20px 0px;
        line-height: 20px;
    }

    .__booking-lesson-1st-info:last-child {
        margin: 20px 0px 20px 0px;
        line-height: 20px;
    }

    .__booking-lesson-2nd {
        margin: 20px 0px 20px 0px;
    }

    .__booking-lesson-3rd,
    .__booking-lesson-3rd-with-select {
        align-self: flex-start;
        justify-self: flex-start;
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        margin: 0px;
        width: 100%;
    }
    
    .__booking-lesson-3rd-price {
        font-size: 18px;
        text-align: right;
        margin: 0px 0px 20px 0px;
    }
    
    .__booking-lesson-3rd-button {
        height: 40px;
        width: 100%;
    }

    .__booking-participants-select {
        max-width: 40%;
    }

    .__booking-options-parent,
    .__booking-participants-parent {
        flex-direction: column;
    }

    .__booking-options-left,
    .__booking-participants-fields {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .__booking-options-button,
    .__booking-participants-button,
    .__booking-questions-button {
        align-self: center;
        width: calc(100% - 40px);
    }

    .__booking-options-left,
    .__booking-participants-field {
        width: calc(100% - 40px);
    }

    .__booking-right {
        min-width: 100%;
        height: 100%;
    }

    .__booking-selection-parent {
        min-width: 100%;
        margin: 0px;
    }

    .__booking-alert-parent {
        width: 80%;
        padding: 20px;
    }
    
    .__booking-alert-button {
        height: 40px;
        width: 100%;
    }

    .__booking-instructors-parent {
        flex-direction: column;
    }
    
    .__booking-instructors-selects {
        width: 100%;
        margin-right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .__booking-instructors-select-parent {
        width: 100%;
    }
    
    .__booking-instructors-button {
        height: 40px;
        width: 100%;
        align-self: center;
    }

    .__booking-instructors-left {
        flex-direction: column;
    }

    .__booking-instructors-checkboxes {
        height: auto;
        margin-bottom: 20px
    }
}

@media only screen and (max-width: 1000px) {
    .__booking-right {
        min-width: 320px;
        height: 100%;
        margin: 0px;
    }

    .__booking-selection-parent {
        min-width: 100%;
        margin: 0px;
    }

    .__booking-detailsbar-participants,
    .__booking-detailsbar-datepicker {
        width: 100% !important;
    }
}