.__footer-parent {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* Comment to correction of ride2peak. Doesn't seem to affect other platforms. */
    /* background-color: var(--background-sitemap-color) */
}

.__footer-sponsor-parent {
    width: 100%;
    min-height: var(--min-height-footer-sponsor);
    background-color: var(--background-footer-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* border-radius: var(--main-border-radius); */
    border-radius: var(--content-border-radius);
}

.__footer-sponsor-item {
    margin: 20px;
}

.__footer-sponsor-image {
    max-height: 50px;
    max-width: 200px;
}

.__footer-navigation-parent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    background-color: var(--background-secondary-color);
}

.__footer-copyright-parent {
    background-color: var(--background-footer-copyright-color);
    height: 56px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.__footer-copyright-label {
    font-family: 'Montserrat';
    font-size: 14px;
    /* color: var(--font-primary-color); */
    color: var(--font-footer-color);
} 

.__footer-copyright-link {
    text-decoration: none;
    color: var(--font-footer-color);
}

@media only screen and (max-width: 900px) {
    .__footer-sponsor-parent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: var(--mobile-padding-footer-sponsor);
    }
    .__footer-sponsor-item {
        margin: auto;
        padding: 10px 5px;
    }
}