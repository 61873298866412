.comingSoon {
    font-size: 24px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ::-webkit-scrollbar { 
    display: none; 
} */

.main-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* background-color: white; */
    max-width: var(--width-container);
    min-width: 305px;
    margin: auto;
    position: relative;

    -webkit-user-select: text;  /* Chrome all / Safari all */
    -moz-user-select: text;     /* Firefox all */
    -ms-user-select: text;      /* IE 10+ */
    user-select: text;
}

.container-content {
    background-color: var(--background-homepage-color);
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.circular-progress {
    margin: 20px 0px 20px 0px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.green-background {
    background-color: #0a8012;
    color: #fff;
}

.clickable {
    cursor: pointer;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#root {
    height: 100%;
    background-color: var(--background-root-color);
}

input {
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
}

a {
    color: var(--primary-color);
}

a:visited {
    color: var(--primary-color);
}

.logo-center {
    width: 80px;
    height: 80px;
    margin-top: 10%;
}

.logo-text {
    color: white;
    margin-bottom: 10%;
}

.main-background {
    background-color: #d5d8ed;
    height: 100%;
}

.splash-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.newsfeed-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    background-color: #d5d8ed;
    height: 100%;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#register-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.white-label-form {
    color: white;
    font-size: 14px;
}

#login-form {
    margin-top: 15%;
}

#login-btn-container {
    display: flex;
    justify-content: center !important;
}

.button {
    border-radius: 5px;
    border: 0px;
    padding: 10px 20px;
    font-size: 14px;
}

.primary-btn {
    margin: 10px;
    background-color: #af191f !important;
    color: white;
}

.sunkhronos-brand {
    color: white;
    font-size: 10px;
    position: fixed;
    bottom: 20px;
}

@keyframes blink {
    0% {
        opacity: 0.7;
    }
    40% {
        opacity: 0.9;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0.9;
    }
    100% {
        opacity: 0.7;
    }
}

.skeleton-blink {
    animation: blink 1s infinite;
}

.skeleton-blink article {
    min-height: 200px;
}

.skeleton-blink article.header, .skeleton-blink article.footer {
    min-height: 70px;
}

footer.footer a {
    color: #17386d !important;
}

.divider {
    height: 1px;
    background-color: #d5d8ed;
}

/* Help */

.__help-parent {
    height: 100%;
    width: 100%;
}

.react-pdf__Document {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}

.react-pdf__Page__canvas {
    border-radius: var(--basic-border-radius);

}

.__help-iframe {
    border: none;
    height: 80vh;
    width: 100%;
}