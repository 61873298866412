.__newsfeed-parent {
  margin: 10px auto 10px auto;
}

/* .__newsfeed-content {
  margin: 10px 5px 10px 5px;
} */

/* .__newsfeed-content:not(:first-child) {
  margin-top: 50px;
}

.__newsfeed-content:not(:last-child) {
  margin-bottom: 50px;
} */

.__newsfeed-no-item {
  font-size: 18px;
  margin: 50px;
}

/* NewsfeedCard */

.__newsfeedcard-parent {
  background-color: var(--background-primary-color);
  height: 100%;
  width: 100%;
  font-size: 14px;
}

.__newsfeedcard-header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.__newsfeedcard-header-left {
  width: fit-content;
  display: flex;
  align-items: center;
}

.__newsfeedcard-header-logo {
  height: 40px;
  width: 40px;
  margin: 12px;
  border-radius: 20px;
}

.__newsfeedcard-header-title {
  color: var(--font-primary-color);
  font-size: 16px;
  font-weight: bold;
  margin: 12px;
}

.__newsfeedcard-header-right {
  width: fit-content;
  text-align: right;
  margin: 12px;
}

.__newsfeedcard-header-time {
  color: var(--ternary-color);
}

.__newsfeedcard-body {
  margin-top: 12px;
  margin-bottom: 12px;
}

.__newsfeedcard-body-title {
  margin: 12px;
  font-weight: bold;
  color: var(--font-primary-color);
  cursor: pointer;
}

.__newsfeedcard-body-image {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.__newsfeedcard-body-image-plus {
  max-height: 350px;
  object-fit: cover;

}

.__newsfeedcard-body-text {
  color: var(--font-primary-color);
  margin: 12px;
  margin-top: 9px;
}

.__newsfeedcard-divider {
  background-color: var(--background-primary-color);
  width: calc(100% - 24px);
  margin: auto;
  height: 1px;
}

.__newsfeedcard-footer {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.__newsfeedcard-footer-left {
  margin: 12px;
  display: flex;
}

.__newsfeedcard-footer-left-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.__newsfeedcard-footer-left-container:hover {
  fill: var(--ternary-color);
  color: var(--ternary-color);
}

.__newsfeedcard-footer-left-container-on {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
  fill: var(--primary-color);
  color: var(--primary-color);
}

.__newsfeedcard-footer-left-container-on:hover {
  fill: var(--primary-color);
  color: var(--primary-color);
}

.__newsfeedcard-footer-svg {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.__newsfeedcard-footer-text-container {
  color: var(--font-primary-color);
  margin: 12px;
  display: flex;
  align-items: center;
}

.__newsfeedcard-footer-text {
  margin: 18px 18px 18px 0px;
}

.__newsfeedcard-comment-parent {
  padding: 0px 12px 12px 12px;
}

/* NewsFeedDetail */

.__newsfeed-detail-parent {
    background-color: var(--background-secondary-color);
    height: 100%;
    width: 100%;
    max-width: 70%;
    margin: 10px auto 10px auto;
}

.__newsfeed-detail-comment-parent {
    margin: 0px 0px 12px 0px;
    width: 100%;
}

.__newsfeed-detail-comment-image {
    width: 30px;
    height: 30px;
}

.__newsfeed-detail-expandable-container {
  box-shadow: none;
}

.__newsfeed-detail-expandable {
  background-color: var(--background-primary-color);
  font-size: 18px;
  height: 50px;
  border-radius: 5px;
}

.__newsfeed-detail-expandable-collapse {
  margin-bottom: 0px;
  background-color: var(--background-secondary-color);
  border: none;
}

.__newsfeed-comment-paper {
  margin: 12px;
}

.__newdfeed-comment-parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    border-radius: 5px;
    background-color: var(--background-primary-color);
}


.__newsfeed-comment-image {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}


.__newdfeed-comment-user-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.__newdfeed-comment-user-name {
    font-weight: bold;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
}


.__newdfeed-comment-text {
    font-size: 14px;
    padding: 2px 10px;
}


.newsfeed-landing-card-parent {
    display: flex;
    flex-direction: column;
}


@media only screen and (max-width: 900px) {

  .__newsfeed-parent {
    width: 100%;
  }

  .__newsfeed-detail-parent {
    max-width: 100%;
  }

  .__newsfeedcard-header-right,
  .__newsfeedcard-header-title {
    font-size: 14px;
  }

  .__newsfeedcard-footer {
    font-size: 12px;
  }

  .__newsfeedcard-footer {
    min-height: 50px;
    height: 100%;
    flex-wrap: wrap;
  }
}