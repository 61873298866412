.__menubar-logo {
    height: var(--menubar-logo-height);
    width: auto;
    /* max-width: var(--menubar-logo-width); */
    margin-top: var(--menubar-logo-margin-top);
    z-index: 1;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.__menubar-parent {
    height: var(--menubar-height);
    background-color: var(--background-secondary-color);
    width: 100%;
    display: grid;
    /* grid-template-columns: 120px repeat(3, calc((100% - 340px)/6)) 100px repeat(3, calc((100% - 340px)/6)) 120px; */
    grid-template-columns: 112px 1fr 115px 1fr 112px;
    border-radius: var(--menubar-border-radius) !important;
}

.__menubar-parent-main {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: var(--menubar-shadow);
    background-color: var(--background-root-color);
    border-top: var(--border-top-color)
}

.__menubar-lang {
    /* padding-top: var(--menubar-lang-logo-padding-top); */
    /* padding-left: 3px; */
    height: 100%;
    grid-column: 1;
}

.__menubar-menus-left {
    /* grid-column-start: 2;
    grid-column-end: 5; */
    grid-column: 2;
    /* display: flex;
    align-items: center;
    justify-content: space-around; */
}

.__menubar-menus-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

}

.__menubar-menus-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

}

.__menubar-central-div {
    grid-column: 3;
}

.__menubar-menus-right {
    /* grid-column-start: 9;
    grid-column-end: 6; */
    grid-column: 4;
    /* display: flex;
    align-items: center;
    justify-content: space-around; */
}

.__menubar-menus-div-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: -3px;
    /* cursor: pointer; */
}

.MuiListItem-button:hover,
.MuiMenuItem-root.__menubar-submenus-hover {
    background-color: var(--button-color) !important;
    color: var(--font-quaternary-color) !important;
}

.__menubar-menus-div-container-active {
    background-color: var(--active-menu);
}

.__menubar-menus-div-container:hover p,
.__menubar-menus-div-container-hover p {
    color: white;
    font-weight: bold;
}

.__menubar-menus-div-container:hover,
.__menubar-menus-div-container-hover {
    background-color: var(--active-menu);
}

.__menubar-menu-button {
    color: var(--primary-color);
    /* cursor: pointer; */
    margin: 0px 2.5px;
    font-size: 16px;
    padding: auto;
    /* padding: 28.75px 0; */
}


.__menubar-menu-paper .MuiList-root {
    margin-top: -25px;
    width: 245px;
}

.MuiList-root:active,
.MuiList-root:focus {
    outline: none;
}

.MuiList-root .Mui-disabled {
    opacity: 1 !important;
}

.__menunbar-menu-item {
    font-size: 14px !important;
    padding-left: 15px !important;
}

.__menunbar-menu-item-child {
    font-size: 14px !important;
    padding-left: 30px !important;
}

.__menunbar-menu-item-none {
    font-size: 14px !important;
    padding-left: 15px !important;
}

.__menunbar-menu-item-none:hover {
    cursor: default;
    background-color: white !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.__menubar-right-div {
    grid-column: 5;
    display: flex;
    flex-direction: row;
}

.__menubar-right-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
}

.__menubar-right-div:hover>.__menubar-right-separator {
    opacity: 0;
}

.__menubar-right-tooltip-img {
    display: block;
    /* vertical-align: middle; */
    filter: var(--img-to-white);
    width: 32px;
    height: 32px;
    align-self: center;
    background-color: transparent;
}

.__menubar-right-separator {
    height: 30px;
    width: 1px;
    position: relative;
    margin-top: 12px;
    background-color: var(--ternary-color);
}
.__menubar-lang-button {
    height: 100%;
    width: 64px;
    margin-top: auto !important;
}

.__menubar-lang-logo {
    display: block;
    /* vertical-align: middle; */
    height: 32px;
    border-radius: 10px;
    background-color: transparent;
    box-shadow: var(--background-secondary-color);
}

.MuiIconButton-root:hover {
    /* background-color: var(--background-primary-color)!important; */
    background-color: initial !important;
    filter: brightness(120%);
}

.__menubar-lang-menu {
    position: absolute;
    float: right;
    margin-top: 0px;
    margin-left: 6px;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-secondary-color);
}

.__menubar-lang-menu-item {
    float: left;
    list-style: none;
    margin: 0px 10px;
    cursor: pointer;
}

.__menubar-lang-menu-flag {
    height: 32px;
    border-radius: 10px;
}

.__menubar-hamburger-icon {
    fill: var(--primary-color);
}

.MuiMenuItem-root {
    font-family: 'Montserrat' !important;
    color: var(--primary-color) !important;
}

@media only screen and (min-width: 901px) {
    .__menunbar-menu-item-parent {
        text-transform: uppercase;
    }

}

@media only screen and (max-width: 900px) {
    .__menubar-parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: var(--menubar-height-mobile);
    }

    .__menubar-menu-button {
        height: initial;
        padding: 0 0;
    }

    .MuiDrawer-paper {
        width: 80%;
    }

    .__menubar-parent-main {
        position: relative;
    }

    .__menubar-logo {
        max-width: var(--menubar-logo-width-mobile);
        height: var(--menubar-logo-height-mobile);
        margin-top: var(--menubar-logo-margin-top-mobile);
        width: auto;
    }
}