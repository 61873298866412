.__sitemap-parent {
    width: 100%;
    background-color: var(--background-sitemap-color);
    position: relative;
    padding: 10px 20px 30px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 10px;
}

.__sitemap-parent-destinations {
    width: 100%;
    background-color: var(--background-sitemap-color);
    position: relative;
    padding: 0px 20px 30px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px 10px;
}

.__sitemap-parent-description {
    width: 100%;
    background-color: var(--background-sitemap-color);
    position: relative;
    padding: 20px 20px 30px 20px;
}

.__sitemap-parent-title {
    width: 100%;
    background-color: var(--background-sitemap-color);
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;

}
.__sitemap-parent-accordion.MuiAccordion-root {
    background-color: var(--background-sitemap-color);
    padding-top: 10px;
}
.__sitemap-parent-accordion .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px!important;
}

.__sitemap-parent-accordion .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px!important;
}

.__sitemap-parent-accordion .__sitemap-parent-title {
    padding-top: 0px;
    padding-right: 0px;
}

.__sitemap-parent-accordion .MuiAccordionSummary-content {
    flex-grow: unset;
}

.__sitemap-parent-accordion .MuiAccordionSummary-root {
    background-color: var(--background-sitemap-color);
    padding: 0px;
    justify-content: flex-start;
}

.__sitemap-parent-accordion .MuiAccordionDetails-root {
    background-color: var(--background-sitemap-color);
    padding: 0px;

}

.__sitemap-parent-accordion.MuiAccordion-root.Mui-expanded {
    margin: 0px;
}

/* .__sitemap-column {
    margin: 50px 8px 50px 8px;
    min-width: 80px;
    width: fit-content;
    height: fit-content;
}

.__sitemap-column:first-child {
    min-width: 450px;
}

.__sitemap-first-column {
    display: flex;
} */

.__sitemap-column-title {
    font-size: 15px;
    margin-bottom: 5px;
}

.__sitemap-parent-title .__sitemap-column-title {
    margin-bottom: 0px;
}

.__sitemap-column-ul {
    list-style-type: none;
    max-height: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
}

.__sitemap-column-ul:first-child {
    margin-right: 40px;
}

.__sitemap-column-li {
    line-height: 18px;
    margin-right: 60px;
}

.__sitemap-column-text {
    font-size: 14px;
    color: var(--font-sitemap-color);
    text-decoration: none;
    cursor: pointer;
    line-height: 1.5;
}

.__sitemap-column-text:hover {
    color: var(--primary-color);
}

.__sitemap-column-text-not-link {
    cursor: default;

}

.__sitemap-column-text-not-link:hover {
    color: var(--font-sitemap-color);

}

.__sitemap-payment-img {
    height: 45px;
    /* opacity: 0.7; */
}

.__sitemap-logos {
    width: 80px;
}

.__sitemap-logos-img {
    width: 100%;
}

@media only screen and (max-width: 900px) {
    .__sitemap-parent-destinations {
        padding: 0px 20px 30px 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px 10px;
    }

    .__sitemap-parent {
        padding: 10px 20px 30px 20px;
        grid-template-columns: 1fr 1fr;
        gap: 20px 10px;
    }

    .__sitemap-logos {
        display: none;
    }

    .__sitemap-column-ul {
        max-height: 200px;
    }

    .__sitemap-column-li {
        margin-right: 28px;
    }
}

@media only screen and (max-width: 500px) {
    .__sitemap-parent-destinations {
        padding: 0px 20px 30px 20px;
        grid-template-columns: 1fr 1fr;
        gap: 10px 10px;
    }

    .__sitemap-parent {
        padding: 10px 20px 30px 20px;
        grid-template-columns: 1fr;
        gap: 20px 10px;
    }
}