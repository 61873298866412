.__account-parent {
    display: flex;
}

.__account-sidebar-medals-icon .cls-1,
.__account-sidebar-medals-icon .cls-2 {
    stroke: var(--primary-color) !important;
}

/* PhotosLibrary */

.__account-photos-header {
    margin: 20px 20px 40px 20px;
}

.__account-photos-title {
    font-size: 22px;
    color: var(--primary-color);
    margin-top: 20px;
    margin-bottom: 20px;
}

.__account-photos-text {
    font-size: 16px;
    color: var(--ternary-color);
    white-space: pre-wrap;
    margin-top: 20px;
    margin-bottom: 20px;
}

.__account-photos-no-image {
    color: var(--font-primary-color);
    margin-top: 50px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.__account-photos-skeleton-rect {
    height: 100% !important;
}

.__account-photos-photo {
    cursor: pointer;
}

.__account-photos-modal-background {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.6);
}

.__account-photos-fullscreen-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    max-width: 80%;
    min-height: 300px;
    max-height: 80%;
    display: block;
}

.__account-photos-fullscreen {
    max-width: 95vh;
    max-height: 95vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Medals */

.__account-medals-parent {
    display: flex;
    flex-direction: column;
}

.__account-medals-section-title {
    margin: 10px;
}

.__account-medals-listing {
    display: flex;
    flex-wrap: wrap;
}

.__account-medals-detail {
    height: fit-content;
    max-width: 90%;
    overflow: auto;
}

.__account-medals-detail-image {
    max-height: calc(95vh - 45px);
    min-height: 350px;
    max-width: 100%;
}

.__account-medals-skeleton-title {
    margin: 0px 30px 0px 10px;
    width: 200px;
    height: 26px !important;
}

/* Customer loyalty */

.__account-loyalty-title {
    margin: 10px 0px 10px 10px;
}

.__account-loyalty-cards {
    display: flex;
    flex-wrap: wrap;
}

.__account-loyalty-no-points {
    margin: 20px 10px 0px 10px;
}

.__account-loyalty-no-points-link {
    color: var(--primary-color);
    cursor: pointer;
    display: inline;
}

.__account-loyalty-section,
.__account-loyalty-explanations {
    display: flex;
    margin-left: 20px;
    background-color: var(--background-primary-color);
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0px;
    line-height: 30px;
}

.__account-loyalty-section {
    align-items: center;
}

.__account-loyalty-explanations {
    flex-direction: column;
}

.__account-loyalty-image {
    height: 100px;
    width: 100px;
    margin-right: 50px;
}

.__account-loyalty-card {
    background-color: var(--background-primary-color);
    border-radius: 10px;
    width: 250px;
    padding: 10px;
    margin: 10px;
}

.__account-loyalty-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.__account-loyalty-card-title {
    font-size: 20px;
}

.__account-loyalty-card-image {
    margin: 10px;
}

.__account-loyalty-card-school {
    font-size: 20px;
    margin: 10px 0px;
}

.__account-loyalty-card-points {
    font-size: 18px;
    margin: 10px 0px;
}

.__account-loyalty-card-level-image {
    height: 24px;
    width: 24px;
    margin: 0px 5px;
}

.__account-loyalty-card-level-bar {
    width: 100%;
    height: 20px;
    background-color: var(--quaternary-color);
    border-radius: 5px;
}

.__account-loyalty-card-level-bar-completion {
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 5px;
}

@media only screen and (max-width: 500px) {
    .__account-photos-fullscreen-container {
        min-width: 200px;
        min-height: 200px;
    }

    .__account-photos-fullscreen {
        max-width: 45vh;
        max-height: 80vh;
    }

    .__account-medals-detail {
        height: auto;
        max-height: 90%;
        max-width: 90%;
    }
    
    .__account-medals-detail-image {
        max-height: 100%;
        max-width: 100%;
    }
}