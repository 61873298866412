/* @font-face {
  font-family: Ubuntu;
  src: url('resources/fonts/Ubuntu-Regular.ttf');
} */

@font-face {
  font-family: Montserrat;
  src: url('resources/fonts/Montserrat-Regular.otf');
  font-display: swap;
}

.MuiAlert-standardWarning {
  border-radius: var(--basic-border-radius) !important;
}

.MuiButtonGroup-root {
  border-radius: var(--basic-border-radius) !important;
}

.MuiInputBase-input,
.MuiIconButton-label,
.MuiSelect-icon {
  color: var(--font-input-color) !important;
}

.MuiFormLabel-root {
  color: var(--font-input-color) !important;
}

.MuiInputAdornment-root {
  color: var(--font-input-color) !important;
}

.MuiPaper-rounded {
  background-color: var(--background-primary-color) !important;
  /* or */
  border-radius: var(--basic-border-radius) !important;

}

.MuiPickersCalendarHeader-iconButton {
  background-color: transparent !important;
}

.MuiTypography-root {
  color: var(--font-input-color) !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: var(--font-quaternary-color) !important;
}

.MuiDrawer-paperAnchorTop {
  width: 100% !important;
}

.__newsflash-modal {
  min-width: 350px !important;
  max-width: 650px !important;
}

.__newsflash-header {
  text-align: center;
  position: relative;
}

.__pick-resort .MuiListItem-button:hover .MuiTypography-root {
  color: white !important;
}

.__pick-resort .MuiListItem-button:hover {
  border-radius: 5px;
}

.__pick-resort .MuiListItem-button:first-child {
  margin-top: 15px;
}

.__pick-resort span {
  text-align: center;
}

.__newsflash-content {
  display: block;
  overflow: auto;
  max-height: calc(95vh - 200px);
}

.__newsflash-modal .__title {
  font-size: 26px;
  margin-right: 30px;
}

.__newsflash-text {
  font-size: 19px;
  text-align: justify;
  white-space: pre-wrap;
}

.__newsflash-image {
  margin-bottom: 15px;
  margin-top: 5px;
  max-width: 100%
}

.__newsflash-header-close {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  fill: var(--ternary-color) !important;
}

.__newsflash-header-close:hover {
  fill: var(--primary-color) !important;
}

.__sitemap-column-title {
  color: var(--font-sitemap-title-color) !important;
}

#body, button, input, textarea {
  font-family: 'Montserrat';
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.__cookie-banner-parent {
  position: sticky;
  bottom: 0px;
  background-color: rgba(226, 239, 248, 0.9);
  padding: 30px;
  font-size: 16px;
  z-index: 1000;
}

.__cookie-banner-cga {
  display: inline;
  color: var(--primary-color);
  cursor: pointer;
}

.__cookie-banner-cancel {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-background);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

@media only screen and (max-width: 900px) {
  .__cookie-banner-parent {
    padding-right: 40px;
  }

  .__cookie-banner-cancel {
    right: 10px;
  }
}