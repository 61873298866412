.snowli-iframe {
    /* border: 1px solid black; */
    position: fixed;
    bottom: 20px;
    right: 40px;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    z-index: 2000;
}

.snowli-iframe.mobile {
    bottom: 0px;
    right: 0px;
}
.snowli-iframe.mobile.opened {
    width: 100%;
}

.chat-button {
    background-color: #2b6fab;
    color: white;
    Font-size: 100%;
    padding: 10px;
    border-radius: 100px;
    border: none;
    outline: none;
}

#snowli-widget {
    opacity: 0;
    transform: scale(.5);
    transition: all .2s ease-in-out;
    transform-origin: bottom right;
}

#snowli-widget:not(.mobile) {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 14px;
}

#snowli-widget.opened {
    opacity: 1;
    transform: scale(1);
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.widget-chat-demo-warning {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(118, 173, 255);
    border: 1px solid rgb(0, 162, 255);
    padding: 5px;
    border-radius: 10px;
    color: white;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 110%;
}

.chat-button-content {
    /* animation-name: chat-button-breathing; */
    animation-duration: 2s;
    animation-iteration-count: 5;
    animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes chat-button-breathing {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    to {
        transform: scale(1);
    }
}

.loading-button-wave {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    background-color: rgba(86, 132, 248, 0.409);
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-name: scale-wave-anim;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border-radius: 100px;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    z-index: 1;
}


.opened .loading-button-wave {
    animation-fill-mode: none;
    animation-direction: none;
    animation-timing-function: none;
    animation-name: none;
    animation-duration: none;
    animation-iteration-count: none;
}

.loading-button-wave:nth-child(2) {
    animation-delay: .4s;
}

.loading-button-wave:nth-child(3) {
    animation-delay: .8s;

}

@keyframes scale-wave-anim {
    0% {
        transform: scale(1) translate(-50%, -50%);
        opacity: 1;
    }

    25% {
        transform: scale(1.25) translate(-50%, -50%);
        opacity: .6;
    }

    40% {
        opacity: 0;
        transform: scale(1.25) translate(-50%, -50%);
    }

    100% {
        opacity: 0;
    }
}

.chat-button-content {
    z-index: 999;
    background-color: #2b6fab;
    padding: 15px;
    border-radius: 100%;
    display: flex;
    background-color: #2b6fab;
    padding: 11px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
}

.chat-button {
    padding: '0px';
    position: 'relative'
}

.chat-button.mobile {
    position: absolute;
    bottom: 10px;
    right: 10px;
}