.__topbar-parent {
    height: 32px;
    background-color: var(--background-root-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
}

.__school-choose {
    max-height: 26px;
    font-size: 13px!important;
    min-width: 150px !important;
    margin-left: 0.5px;
}

.__school-choose-item {
    font-size: 13px!important;
}

.__school-choose-item.Mui-selected {
    background-color: var(--button-color)!important;
    color: var(--font-quaternary-color)!important;
}

.__picker-school {
    display: var(--picker-school-diplay)!important;
}

.MuiPaper-rounded::-webkit-scrollbar {
    width: 8px;
}

.MuiPaper-rounded::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
}

.MuiPaper-rounded::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.__schools-info {
    font-size: 14px;
    color: var(--ternary-color);
    display: flex;
}

.__schools-info a,
.__schools-info span {
    text-decoration: none;
    margin-right: 5px;
    color: var(--ternary-color);
    display: flex;
    align-items: center;
}

.__schools-info a svg{
    opacity: 0.85;
    color: var(--ternary-color);
    font-size: 24px;
}

.__schools-info a:hover svg {
    opacity: 1;
    color: var(--hover-color);
}

.__schools-info a:last-child {
    margin-right: 0.5px;
}

.__schools-info a:hover {
    text-decoration: none;
    color: var(--hover-color);
}


.__topbar-select {
    min-width: 150px !important;
    height: 24px !important;
    margin-left: 0.5px;
}

.__topbar-select select {
    font-size: 13px !important;
    padding-right: 10px !important;
}


@media only screen and (max-width: 900px) {
    .__schools-info {
        font-size: 13px;
    }

    .__schools-info a {
        margin-left: 10px;
    }

    .__schools-info a svg{
        opacity: 1;
    }
}