.__reset-password-parent {
  margin: 100px auto 100px auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.__reset-password-button {
  width: 50%;
  height: 44px;
  margin: 0px auto 0px auto;
}

.__reset-password-success {
  color: var(--font-primary-color);
  font-size: 20px;
  margin: 100px auto 100px auto;
}

.__reset-password-textfield-container {
  height: 44px;
  width: 100%;
}
.__reset-password-error {
  height: 44px;
  width: 100%;
}


@media only screen and (max-width: 900px) {
  .__reset-password-parent {
    width: 100%;
  }
}