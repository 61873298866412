.__hamburger-parent {
    background-color: var(--background-homepage-color)!important;
    margin: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.__hamburger-content {
    margin-top: 20px;
    background-color: transparent;
    min-width: 180px;
    /* max-width: 300px; */
    height: 100%;
    overflow: auto;
}

.__hamburger-top-div {
    display: flex;
}

.__hamburger-top-icon {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.__hamburger-top-image {
    width: 32px;
    height: 32px;
    align-self: center;
    background-color: transparent;
    filter: var(--img-to-white);  
}

.__hamburger-top-text-logged {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__hamburger-top-text-divider {
    height: 1px;
    border: none;
    background-color: var(--ternary-color);
    width: 20%;
    opacity: 0.5;
    margin: 5px 0px 5px 0px;
}

.__hamburger-top-text {
    font-size: 12px;
    color: var(--ternary-color);
    text-align: center;
}

.__hamburger-top-divider {
    height:30px;
    width:1px;
    position: relative;
    margin-top: 12px;
    background-color: var(--ternary-color);
}

.__hamburger-divider {
    margin: auto;
    margin-top: 10px;
    height: 1px;
    width: 85%;
    position: relative;
    background-color: var(--ternary-color);
}

.__hamburger-menu-parent {
    margin: 10px 0px;
    line-height: 40px;
    padding-left: 5px;
}

.__hamburger-menu-parent .__menubar-menu-button {
    color: var(--font-title-color);
    font-size: 14px;
    margin-left: 20px;
}

.__hamburger-expandable-header {
    font-family: 'Montserrat' !important;
    background-color: transparent !important;
    font-size: 14px !important;
    color: var(--primary-color) !important;
    min-height: 0px!important;
}

.__hamburger-expandable-header .__expandable-icon {
    margin-right: 20px;
}

.__hamburger-expandable-container {
    box-shadow: none !important;
}

.__hamburger-expandable-item {
    color: var(--font-title-color);
    font-size: 14px;
    /* margin-left: 22px; */
    margin-left: 35px;
    font-family: 'Montserrat';
}

/* PWA */

.__hamburger-pwa-background {
    height: fit-content;
}

.__hamburger-pwa-paper {
    margin: 10px;
}

.__hamburger-pwa-parent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--background-primary-color);
    border-radius: 5px;
    padding: 10px;
}

.__hamburger-pwa-content {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.__hamburger-pwa-logo {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.__hamburger-pwa-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 10px 10px 5px 0px;
}

.__hamburger-pwa-button-container {
    width: fit-content;
}

.__hamburger-pwa-not-now {
    background-color: transparent;
    color: var(--primary-color);
    width: fit-content;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid var(--primary-color);
}

.__hamburger-pwa-install {
    width: fit-content;
    padding: 10px;
    border-radius: 20px;
}