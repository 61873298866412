/* Modal */

.__modal-parent {
    /* background-color: var(--background-secondary-color); */
    background-color: var(--background-modal-color);
    font-size: 16px;
    max-width: 95%;
    max-height: 95vh;
    padding: 20px;
    /* overflow: auto; */
    border-radius: var(--basic-border-radius);
}

.__cart-settings-info {
    max-height: calc(95vh - 160px);
    overflow: auto;
}

.__modal-no-login {
    width: 800px;
    /* height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden; */
}

.__modal-payment-link {
    text-align: center;
    padding: 10px;

}

/* Popper */

.__popper-parent {
    background-color: var(--background-primary-color);
    box-shadow: 0 0px 0px 0 #00000000, 0 0px 2px 0 var(--primary-color), 0 2px 1px -1px #ffffff;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    font-size: 14px;
    max-width: 400px;
    color: var(--primary-color);
}

.__popper-parent ul,
.__products-card-text ul {
    padding-left: 30px;
}

.__popper-parent p:not(:last-child) {
    padding-bottom: 10px;
}

/* Snackbar */

.__snackbar-message {
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    max-width: 400px;
}

.__snackbar-icon {
    margin-right: 20px;
}

/* PWA Modal */

.__pwa-parent {
    background-color: var(--background-secondary-color);
    font-size: 16px;
    width: 400px;
    max-width: 95%;
}

.__pwa-header {
    text-align: center;
    border-bottom: 1px solid var(--ternary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__pwa-logo {
    height: 50px;
    width: 50px;
    margin: 10px 10px 0px 10px;
}

.__pwa-title {
    margin: 20px;
}

.__pwa-content {
    margin: 20px;
}

.__pwa-icon {
    width: 24px;
    height: 24px;
    margin-left: 5px;
    margin-right: 5px;
}

.__pwa-text1 {
    margin-bottom: 10px;
}

.__pwa-text2 {
    display: inline-block;
}

.__pwa-text3 {
    display: inline-block;
    margin-right: 5px;
}

.__pwa-text4 {
    font-weight: bold;
    display: inline-block;
}

.__pwa-button-install {
    background-color: var(--primary-color);
}

.__pwa-button-cancel {
    background-color: var(--error-color);
}

/* Error Snackbar */

.__snackbar-content .MuiSvgIcon-root {
    color: white;
}

.__snackbar-content .MuiIconButton-root:hover .MuiSvgIcon-root,
.__snackbar-content .MuiSvgIcon-root:hover {
    color: #53535d;

}

.__error-snackbar-title,
.__error-snackbar-title h2 {
    font-size: 18px !important;
    font-weight: bold;
    font-family: 'Montserrat' !important;
    margin: 20px 0px 10px 0px;
    color: inherit !important;
    color: var(--font-quaternary-color) !important;
}

.__error-snackbar-text {
    font-size: 15px;
    margin: 10px 0px 20px 0px;
    text-align: justify;
    color: var(--font-quaternary-color) !important;
}

@media only screen and (max-width:900px) {
    .__snackbar-message {
        max-width: 215px;
    }
    .__cart-settings-info {
        max-height: calc(95vh - 185px);
        overflow: auto;
    }
}

@media only screen and (max-width:600px) {
    .__snackbar-message {
        max-width: 270px;
    }
}