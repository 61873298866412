/* MyLessons */

.__lessons-content-listing {
    display: flex;
    flex-wrap: wrap;
}

.__lessons-content-title {
    margin: 10px;
}

.__lessons-button-container {
    margin: 10px auto 10px auto;
    width: fit-content;
}

.__lessons-button {
    background-color: transparent;
    color: var(--primary-color);
    border: var(--primary-color) solid 1px;
    padding: 10px;
    border-radius: 5px;
}

.__lessons-button-text {
    font-size: 13px;
    text-transform: none;
    font-weight: bold;
    cursor: pointer;
}

.__lessons-no-lesson {
    color: var(--font-primary-color);
    margin: 30px 0px 30px 0px;
    display: flex;
    justify-content: center;
    font-size: 20px;
}

/* LessonCard */

.__lessons-card-upcoming,
.__lessons-card-completed {
    max-width: 250px;
    min-width: 150px;
    width: 21%;
    display: inline-block;
    border-radius: 10px;
    margin: 10px;
}

.__lessons-card-upcoming {
    background-color: var(--background-primary-color) !important;
}

.__lessons-card-completed {
    background-color: transparent !important;
}

.__lessons-card-image {
    width: calc(100% - 20px);
    aspect-ratio: 1;
    margin: 10px 10px 0px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.__lessons-card-teacher-upcoming,
.__lessons-card-teacher-completed,
.__lessons-card-text,
.__lessons-card-title,
.__lessons-card-details {
    color: var(--font-primary-color);
    margin: 10px;
    word-break: break-word;
}

.__lessons-card-teacher-upcoming {
    font-size: 20px;
}

.__lessons-card-teacher-completed {
    font-size: 12px;
}

.__lessons-card-text {
    font-size: 14px;
}

.__lessons-card-title {
    font-size: 16px;
}

.__lessons-card-details {
    font-size: 14px;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: underline;
    margin-bottom: 25px;
}

/* LessonDetails */

.__lessons-details-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.6);
}

.__lessons-details-parent {
    position: absolute;
    background-color: var(--background-modal-color);
    border-radius: var(--basic-border-radius);
    width: 80%;
    max-width: 500px;
    max-height: 90%;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border-radius: 5px; */
}

.__lessons-details-section:not(:first-child) {
    margin-top: 5px;
}

.__lessons-details-section {
    margin-bottom: 5px;
    font-size: 15px;
}

.__lessons-details-key {
    font-weight: bold;
    color: var(--font-primary-color);
}

.__lessons-details-header {
    position: relative;
    background-color: var(--background-primary-color);
    padding: 10px;
    border-radius: 5px 5px 0px 0px;
}

.__lessons-details-header-title {
    color: var(--primary-color);
    font-size: 20px;
    padding: 10px 40px 10px 10px;
}

.__lessons-details-header-text {
    padding: 0px 5px 10px 10px;
    color: var(--font-primary-color);
}

.__lessons-details-cross-image {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: var(--ternary-color);
}
.__lessons-details-overview-span{
    color: var(--font-primary-color);
}

.__lessons-details-title {
    background-color: var(--background-primary-color);
    color: var(--font-primary-color);
    text-transform: uppercase;
    font-size: 18px;
    padding: 5px 0px 5px 20px;
}

.__lessons-details-overview {
    margin: 20px;
}

.__lessons-details-overview-span:not(:first-child) {
    padding-top: 5px;
}

.__lessons-details-overview-span:not(:last-child) {
    padding-bottom: 5px;
}

.__lessons-details-overview-span {
    display: flex;
    justify-content: space-between;
}

.__lessons-details-options-values {
    display: flex;
}

.__lessons-details-options-values-quantity {
    margin-right: 10px;
}

.__lessons-details-teacher {
    display: flex;
    margin: 20px;
    justify-content: space-between;
}

.__lessons-details-teacher-avatar {
    width: 160px;
    height: 160px;
    border-radius: 100%;
}

.__lessons-details-teacher-infos {
    text-align: right;
}

.__lessons-details-teacher-infos-name {
    padding-bottom: 5px;
    font-size: 16px;
}

.__lessons-details-teacher-infos-info {
    padding-top: 5px;
    padding-bottom: 5px;
}

.__lessons-details-teacher-image-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 15px;
}

.__lessons-details-teacher-image {
    height: 42px;
    width: 42px;
    cursor: pointer;
}

.__lessons-details-teacher-image svg path {
    fill: #2d9340;
}

.__lessons-details-teacher-image svg circle {
    stroke: #2d9340;
}

.__lessons-details-teacher-image:hover svg path {
    fill: #b0d1b7;
}

.__lessons-details-teacher-image:hover svg circle {
    stroke: #b0d1b7;
}

.__lesson-details-participants {
    display: flex;
    justify-content: space-between;
    background-color: var(--background-primary-color);
    text-transform: uppercase;
    padding: 5px 20px 5px 20px;
    font-size: 18px;
    color: var(--font-primary-color);
}

.__lesson-details-participant-divider {
    margin: 20px auto 20px auto;
    border: none;
    background-color: var(--ternary-color);
    height: 1px;
    width: 100%;
}

.__lessons-details-more {
    margin: auto;
    font-size: 20px;
    font-weight: bold;
}

.__lessons-details-checklist {
    margin: 20px;
    height: fit-content;
    max-height: 160px;
    overflow: scroll;
}

.__lessons-details-checklist-el:not(:first-child) {
    padding-top: 5px;
}

.__lessons-details-checklist-el:not(:last-child) {
    padding-bottom: 5px;
}

.__lessons-details-overview-qrcode {
    align-items: center;
    margin: 20px 0px;
    cursor: pointer;
}

.__lessons-details-qrcode-fullscreen-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.8);
}

.__lessons-details-qrcode-fullscreen {
    width: 250px !important;
    height: 250px !important;
}

/* Skeletons */

.__lessons-skeleton-img {
    width: calc(100% - 20px);
    padding-top: calc(100% - 20px);
    position: relative;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.__lessons-skeleton-title {
    margin: 0px 30px 0px 10px;
    height: 24px !important;
}

.__lessons-skeleton-text {
    margin: 0px 10px 0px 10px;
    height: 18px !important;
}

.__lessons-skeleton-link {
    margin: 0px 10px 0px 10px;
    width: 50%;
    height: 16px !important;
}


.__lessons-details-skeleton-title {
    height: 100px !important;
    margin: 0px 0px 5px 0px;
}

.__lessons-details-skeleton-overview {
    height: 35px !important;
    margin: 0px 0px 10px 0px;
}

.__lessons-details-skeleton-key {
    height: 16px !important;
    margin: 10px;
    width: 30%;
}

.__lessons-details-skeleton-value {
    height: 16px !important;
    margin: 10px;
    width: 50%;
}

.__lessons-details-skeleton-line {
    display: flex;
    justify-content: space-between;
}

/* Smartphone */

@media only screen and (max-width: 500px) {

    .__lessons-content:not(:last-child) {
        margin-bottom: 50px;
    }

    .__lessons-card-completed {
        max-width: 50%;
        width: 50%;
        display: inline-block;
        margin: 0px;
    }

    .__lessons-card-upcoming {
        max-width: 90%;
        width: 90%;
        display: inline-block;
        margin: 10px;
    }

    .__lessons-button-container {
        margin-top: 20px;
    }

    .__lessons-details-parent {
        max-height: 101%;
        width: 101%;
        height: 101%;
        border-radius: 0px;
    }

    .__lessons-details-cross-image {
        width: 35px !important;
        height: 35px !important;
        top: 15px;
        right: 15px;
    }
}
