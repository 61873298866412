.__header-parent {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--width-container);
    position: relative;
    margin-top: var(--header-image-margin-top);

}

.__header-parent-with-search {
    /* background-color: var(--quaternary-color); */
    background-color: var(--background-topbar-color);


}

.__header-parent-without-search {
    /* background-color: var(--background-homepage-color); */
    background-color: var(--background-root-color);
}

.__header-parent-main {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* position: sticky;
    top: -675px; */
}

.__header-base {
    min-height: 50px;
    display: var(--display-search-bar);
    justify-content: flex-start;
    align-items: center;
    background: var(--header-color);
    border-radius: var(--main-border-radius);
}

.__header-parent-main-header .__header-base {
    border-radius: var(--header-image-border-radius);
    background: var(--main-header-color);
}


.__header-base-text {
    color: var(--font-header-base-color);
    font-size: 18px;
    font-family: 'Montserrat';
    margin-left: 22px;
}

.__header-base-search-bar {
    width: 100%;
    height: 87px;
    /* background-color: var(--quaternary-color);
    border-radius: var(--menubar-border-radius); */
}

.__header-image-parent {
    display: flex;
    flex-direction: column;
}

.__header-image,
.__footer-image {
    background-color: var(--background-secondary-color);
    object-fit: cover;
    width: 100%;
    display: block;
}

.__header-image {
    max-height: var(--height-header-image);
    /* border-radius: var(--header-image-border-radius); */
    min-height: var(--header-image-min-height);
}

.__footer-image {
    margin: 48px -28px 28px -28px;
    width: calc(100% + 56px);
}

.__header-image-label-1 {
    background-color: var(--secondary-color);
    padding: 10px 20px 10px 20px;
    color: var(--font-quaternary-color);
    position: absolute;
    z-index: 2;
    bottom: var(--header-image-label-1-bottom);
    right: 0px;
    /* opacity: 0.80; */
    font-size: var(--header-image-label-1-font-size) !important;
    font-weight: 100;
    align-items: center;
    border-radius: var(--header-image-label-1-border-radius);
    width: var(--header-image-label-1-width);
}

.__header-image-label-2 {
    background-color: var(--background-secondary-color);
    padding: 10px 20px 10px 20px;
    color: var(--font-primary-color);
    align-items: center;
    position: absolute;
    z-index: 2;
    bottom: var(--header-image-label-2-bottom);
    right: 0px;
    /* opacity: 0.80; */
    align-items: center;
    font-size: 22px !important;
    font-weight: 100;
    border-radius: var(--header-image-label-1-border-radius);
    display: var(--header-image-label-2-display);
}

.__header-skeleton {
    background-color: white !important;
}

@media only screen and (max-width: 900px) {
    .__footer-image {
        margin: 28px -13px 28px -13px;
        width: calc(100% + 26px);
    }

    /* .__header-parent-main {
        position: sticky;
        top: -286px;
    } */
    .__header-image-label-1 {
        padding: 5px 10px;
        bottom: var(--header-image-label-1-bottom-mobile);
        font-size: 16.5px !important;
        text-align: var(--header-image-label-1-text-align-mobile);
        width: var(--header-image-label-1-width-mobile);
        border-radius: var(--header-image-label-1-border-radius-mobile);
    }

    .__header-image-label-2 {
        padding: 4px 10px;
        font-size: 14.5px !important;
        text-align: right;
        bottom: var(--header-image-label-2-bottom-mobile);
    }

    .__header-base-search-bar {
        height: fit-content;
    }

    .__header-base-text {
        font-size: 14px;
    }

    .__header-image-label-1,
    .__header-image-label-2 {
        transition: opacity 0.5s ease-in-out;
    }

    .__header-parent:hover .__header-image-label-1,
    .__header-parent:hover .__header-image-label-2 {
        opacity: 0;
    }
}