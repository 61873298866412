/*Index*/

.__products-index-parent {
  display: flex;
}

.__products-index-left {
  width: 20%;
  height: 100%;
  margin: 10px;
  min-width: 279px;
}

.__products-index-right {
  width: 80%;
  margin: 10px;
}

.__products-index-no-card-container {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

/* LessonFilter */

.__products-filter-button-container {
  height: 40px;
  width: 100%;
  margin: auto;
}

/* .__products-filter-button {
  background-color: var(--background-secondary-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
} */

/* LessonFiltersSmartphone */

.__products-filter-sp-parent {
  height: 40px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--quaternary-color);
  margin: 10px auto 10px auto;
  position: sticky;
  top: 10px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),
        0px 1px 1px 0px rgba(0,0,0,0.14),
        0px 2px 1px -1px rgba(0,0,0,0.12);
}

.__products-filter-sp-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.__products-filter-sp-svg {
  filter: var(--img-to-white);  
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.__products-filter-sp-filter {
  color: var(--font-primary-color);
  font-size: 18px;
  font-family: 'Montserrat';
}

.__products-filter-sp-drawer {
  height: 100%;
  width: 90%;
  margin: 20px auto 20px auto;
}

.MuiDrawer-paperAnchorRight {
  background-color: var(--background-homepage-color)!important;
}

/*Card*/

.__products-card-frame {
  margin: 0px 10px 20px 10px;
}

.__products-card-frame:not(:first-child) {
  margin-top: 20px;
}

.__products-card-parent {
  width: 100%;
  min-height: 200px;
  background-color: var(--background-primary-color);
  border-radius: var(--basic-border-radius);
  display: flex;
  transition: transform .2s;
}

.__products-card-parent-enabled {
  cursor: pointer;
}

.__products-card-parent-disabled {
  cursor: not-allowed;
}

.__products-card-parent:hover {
  transform: scale(1.02);
}

.__products-card-left-pane {
  padding: 10px;
  overflow: hidden;
  height: 100%;
  display: flex;
}

.__products-card-image,
.__products-card-image-unavailable {
  /* max-width: 200px; */
  max-height: 200px;
  width: 100%;
  height: auto;
  padding-bottom: 100%;
  background: no-repeat center;
  background-size: cover;
  overflow: hidden;
  border-radius: var(--basic-border-radius);
}

.__products-card-image {
  cursor: pointer;
}

.__products-card-right-pane {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.__products-card-header {
  margin-bottom: 10px;
}

.__products-card-title,
.__products-card-title-unavailable {
    color: var(--primary-color);
    font-size: 24px;
    width: fit-content;
}

.__products-card-title {
  cursor: pointer;
}

.__products-card-body {
  margin-top: 10px;
  margin-bottom: 10px;
}

.__products-card-text {
    text-align: justify;
    font-size: 14px;
    color: var(--ternary-color);
    width: 100%;
    line-break: auto;
}

.__products-card-text p {
  margin: 0px 0px 10px 0px;
}

.__products-card-text a {
  color: var(--primary-color);
}

.__products-card-bottom {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.__products-card-bottom-left {
  margin-right: 10px;
}

.__products-card-info {
  display: flex;
}

.__products-card-info:first-child {
  margin-bottom: 10px;
}

.__products-card-info-title {
  color: var(--font-ternary-color);
  font-size: 16px;
  margin-right: 10px;
}

.__products-card-info-text {
  color: var(--ternary-color);
  font-size: 16px;
  margin-right: 10px;
}

.__products-card-bottom-right {
  width: 150px;
  text-align: right;
}

.__products-card-button-container {
  height: 40px;
}

.__products-card-bottom-from {
  font-size: 16px;
  color: var(--ternary-color);
  margin-bottom: 10px;
}

.__products-card-bottom-chf {
  font-size: 16px;
  margin-bottom: 10px;
}

.__products-card-button {
  height: 40px;
  font-size: 16px;
}

/*Lesson date*/

.__products-date-frame {
    background-color: var(--quaternary-color);
    padding: 10px;
    height: 100%;
    width: 100%;
    border-radius: var(--basic-border-radius);
}

.__products-date-title {
  color: var(--font-ternary-color-inverse);
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.__products-date-picker {
  height: 40px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.__products-date-picker .MuiInput-input {
  color:  var(--font-ternary-color-inverse)!important;
}

.__products-date-select {
  height: 40px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.__product-date-selected-school {
  color: var(--font-primary-color);
  font-size: 16px;
  margin-left: 15px;
  margin-top: 5px;
}

/*Checkbox filter*/

/* .__products-checkboxfilter-group {
} */

.__products-checkboxfilter-parent {
  font-family: 'Montserrat';
  font-size: 16px;
  padding: 0px 0px 2px 0px;
  background-color: var(--background-primary-color);
}

.__products-checkboxfilter-parent:first-child {
  margin-top: 10px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.__products-checkboxfilter-parent:last-child {
  margin-bottom: 30px;
  border-bottom-right-radius: var(--basic-border-radius);
  border-bottom-left-radius: var(--basic-border-radius);
  padding-bottom: 10px;
}

.__products-checkboxfilter-parent:first-child .__products-checkboxfilter-title {
  border-top-right-radius: var(--basic-border-radius);
  border-top-left-radius: var(--basic-border-radius);
}

.__products-checkboxfilter-label{
  font-family: 'Montserrat';
  font-size: 16px;
}

.__products-checkboxfilter-title {
  background-color: var(--quaternary-color);
  color: var(--font-ternary-color-inverse);
  font-size: 16px;
  padding: 10px;
  /* border-radius: var(--basic-border-radius); */
}

.__products-checkboxfilter-checkbox-container {
  margin-top: 5px;
  margin-bottom: 5px;
}

.__products-checkboxfilter-checkbox {
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.__products-checkboxfilter-checkbox .__checkbox-svg {
  margin-left: auto;
}

/* Smartphone */

@media only screen and (max-width: 900px) {

  .__products-index-parent {
    flex-direction: column;
    align-items: center;
  }

  .__products-index-right {
    width: 100%;
  }

  .__products-card-frame {
    margin: 0px 10px 20px 10px;
  }

  .__products-card-frame:not(:first-child) {
    margin-top: 20px;
  }

  .__products-card-right-pane {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .__products-card-image {
    max-width: 100%;
    max-height: 100%;
  }

  .__products-card-bottom {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .__products-card-bottom-left {
    margin: 0px;
    width: 100%;
  }

  .__products-card-bottom-right {
    margin-top: 0px;
    width: 50%;
  }

  .__products-checkboxfilter-title {
    margin-top: 15px;
  }

  .__products-checkboxfilter-checkbox-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .__products-filter-parent {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .__products-filter-button-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0px;
  }

  .__products_filter-button-border {
    min-width: 125px;
    width: 40%;
  }

  .__products-filter-button-parent {
    margin-top: 60px;
    position: sticky;
    width: 100%;
    right: 5px;
    bottom: 15px;
    height: 100%;
  }

  .__products-filter-button-background {
    background-color: var(--background-secondary-color);
    filter: blur(10px);
    width: 100%;
    height: 80px;
    bottom: -15px;
    z-index: -1;
    position: absolute;
  }
}
