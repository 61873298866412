.__landing-container {
  padding: 20px 20px;
}

/* NewsfeedCard */

.__landing-newsfeed-card-parent {
  display: flex;
  flex-direction: column;
  width: calc(100%/3 - 20px/3);
  min-height: 145px;
  justify-content: center;
  padding: 2px 20px;
  background-color: var(--background-primary-color);
  margin-right: 10px;
  margin-top: 10px;
  font-family: 'Montserrat';
}

.__landing-newsfeed-card-parent:last-child {
  margin-right: 0;
}

.__landing-newsfeed-card-skeleton {
  margin: 10px;
}

.__landing-newsfeed-card-company-name {
  color:var(--font-secondary-color);
  font-size: 15px;
  font-weight: 400;
}

.__landing-newsfeed-card-title {
  color:var(--font-primary-color);
  font-size: 14px;
  padding: 2px 0px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
}

.__landing-newsfeed-card-description,
.__school-site-content {
  font-size: 14px;
  color: var(--ternary-color);
  margin-top: 5px;
  overflow-wrap: normal;
  text-align: justify;
}

.__landing-newsfeed-card-description {
  overflow-wrap: break-word;
}

.__school-site-content {
  column-count: 2;
  column-gap: 30px;
  column-fill: balance;
  margin-bottom: 50px;
}

.__school-site-content-sitemap{
  column-count: 4;
  column-gap: 30px;
  column-fill: balance;
  width: 100%;
  margin-bottom: 28px;
}
.__sitemap-parent-content-sitemap-footer {
  width: 100%;
  background-color: var(--background-sitemap-color);
  position: relative;
  padding: 10px 20px 30px 20px;

}

.__school-site-content-sitemap-footer {
  column-count: 4;
  column-gap: 30px;
  column-fill: balance;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 50px;
}

.__school-site-content-sitemap p {
  margin-bottom: 10px;
  color: var(--font-title-color);
  font-weight: 100;
  text-transform: lowercase;
  font-family: 'Montserrat';
  font-size: 22px;
}

.__school-site-content-sitemap-footer p {
  color: var(--font-sitemap-title-color)!important;
  font-size: 15px;
  margin-bottom: 5px;
}

.__school-site-content-sitemap p:first-letter {
  text-transform: uppercase!important;
}

.__avoid-break {
  break-inside: avoid-column;
  page-break-inside: avoid!important;
  margin-bottom: 20px;
}

.__school-site-content-sitemap a {
  color: var(--ternary-color);
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 32px;
  margin-left: 2px;
}

.__school-site-content-sitemap-footer a {
  font-size: 14px;
  color: var(--font-sitemap-color);
  text-decoration: none;
  cursor: pointer;
}

/* .__sitemap-parent li {
  display: flex;
} */

.__sitemap-parent li p,
.__sitemap-parent li a {
  line-height: 28px;
}
.__sitemap-parent li a {
  display: block;
  min-width: 54px;
  width: fit-content;

}

.__school-site-content-sitemap a:hover,
.__school-site-content-sitemap-footer a:hover {
  color: var(--primary-color);
}

.__school-site-content-sitemap li {
  list-style: none;
}

.__school-site-content-sitemap-footer li {
  list-style: none;
}

.__school-site-content p {
  line-height: 24px;
  margin-bottom: 10px;
}

.__landing-lesson-card-parent .__landing-newsfeed-card-description {
  cursor: pointer;
}

.__landing-newsfeed-card-read-more {
  font-size: 14px;
  color:  var(--font-title-color);
  padding-top: 12px;
  cursor: pointer;
  width: fit-content;
  height: 30px;
}

.__landing-newsfeed-card-read-more:hover {
  border-bottom: 1px solid;
}

/* LessonCard */

.__landing-lesson-card-parent {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  min-height: 212px;
  padding: 15px 10px 15px 10px;
  font-family: 'Montserrat';

}

.__landing-lesson-card-parent-4 {
  display: flex;
  flex-direction: column;
  width: 25%;
  min-height: 212px;
  padding: 15px 10px 15px 10px;
  font-family: 'Montserrat';

}

.__landing-lesson-card-parent:nth-child(3n+1) {
  padding-left: 0px;
  padding-right: 20px;
}

.__landing-lesson-card-parent:nth-child(3n+3) {
  padding-left: 20px;
  padding-right: 0px;
}

.__landing-lesson-card-parent-4:nth-child(4n+1) {
  padding-left: 0px;
  padding-right: 22.5px;
}

.__landing-lesson-card-parent-4:nth-child(4n+2) {
  padding-left: 7.5px;
  padding-right: 15px;
}

.__landing-lesson-card-parent-4:nth-child(4n+3) {
  padding-left: 15px;  
  padding-right: 7.5px;
}

.__landing-lesson-card-parent-4:nth-child(4n+4) {
  padding-left: 22.5px;
  padding-right: 0px;
}


.__landing-lesson-card-skeleton {
  width: calc(100%/3 - 20px)!important;
  /* min-width: 100%; */
  min-height: 212px;
  margin: 0px 10px 0px 10px;
}

.__landing-lesson-card-skeleton-rect {
  padding-top: 100%!important;
}

.__landing-lesson-card-skeleton:first-child {
  margin-left: 0;
}

.__landing-lesson-card-skeleton:last-child {
  margin-right: 0;
}

.__landing-lesson-card-image-container {
  border-radius: var(--basic-border-radius);
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.__landing-lesson-card-image-container .__landing-lesson-card-image {
  display: block;
}

.__landing-lesson-card-image {
  width: 100%;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  border-radius: var(--basic-border-radius);
}

.__landing-lesson-card-image-container:hover .__landing-lesson-card-image {
  transform: scale(1.1);
}

/* .__landing-lesson-card-title {
  color:var(--font-title-color);
  font-size: 15px;
  font-weight: bold;
  padding: 2px 0px;
  margin-top: 5px;
  width: fit-content;
  cursor: pointer;
  min-height: 22px;
} */

.__landing-lesson-card-text-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 2; */
  /* padding: 40px 20px 5px 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.50) 100%); */
  padding: 18px 20px 5px 20px;
  background: var(--square-card-color);
  max-height: 60px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  border-bottom-right-radius: var(--basic-border-radius);
  border-bottom-left-radius: var(--basic-border-radius);
  /* border-top: var(--border-top-square); */
  cursor: pointer;

/* height: 100%; => __landing-lesson-card-image-container:hover .__landing-lesson-card-text-content*/
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; */
}

.__landing-lesson-card-text-content .__landing-lesson-card-title {
  color: var(--font-square-color);
  font-size: 1.05em;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;

}

.__landing-lesson-card-text-content .__landing-lesson-card-title-more {
  display: none;

}

.__landing-lesson-card-text-content .__landing-newsfeed-card-description {
  color: var(--font-square-color);
  font-weight: bold;
  /* height: 0px; */
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  margin-top: 20px;
}


/*  (max-height to height: 100%; => __landing-lesson-card-image-container:hover .__landing-lesson-card-text-content & .__landing-lesson-card-text-content */
/* .__landing-lesson-card-image-container:hover .__landing-lesson-card-text-content{
  justify-content: center;
} */

.__landing-lesson-card-image-container:hover .__landing-newsfeed-card-description {
  /* max-height: 100%; */
  margin-bottom: 15px;
}

.__landing-lesson-card-image-container:hover .__landing-lesson-card-text-content {
  opacity: 0.96;
  max-height: 100%;
}

.__landing-lesson-card-text-content .__landing-newsfeed-card-description-more {
  display: none;
}

.__landing-lesson-card-image-container:hover .__landing-newsfeed-card-description-more {
  display: none;
}

.__landing-lesson-card-image-container:hover .__landing-lesson-card-text-content {
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 1) 100%); */
  background: var(--square-card-color);
}


/* .__landing-lesson-card-title:hover {
  border-bottom: 1px solid;
} */

.__landing-lesson-card-text {
  font-size: 14px;
  color: var(--ternary-color);
}

/* About */

.__landing-about {
  display: flex;
  flex-direction: column;
  padding: var(--landing-about-padding);
}

.__landing-about-logo {
  width: 120px;
  height: auto;
  margin-right: 10px;
  background-color: transparent;
  box-shadow: var(--background-secondary-color);
}

.__landing-about-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.__landing-about-sub-desc {
  font-size: var(--landing-about-sub-desc-font-size);
  color: var(--font-primary-color);
  font-family: 'Montserrat';
  font-weight: 600;
}

.__landing-about-desc {
  color: var(--ternary-color);
  font-size: var(--landing-about-desc-font-size);
  font-family: 'Montserrat';
  display: inline-block;
  margin-top: 8px;
  text-align: justify;
  line-height: 1.2;
}

.__landing-about-desc p:not(:last-child)  {
  margin-bottom: 10px;
}



/* Section */

.__landing-section-parent {
  /* margin: 20px 10px 40px 10px; */
  margin-bottom: 0px;
  width: 100%;
}

.__landing-section-header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  /* margin-top: 45px; */
}

.__landing-section-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.__landing-section-view-all {
  font-size: 14px;
  color:  var(--font-primary-color);
  cursor: pointer;
  float: right;
  font-family: 'Montserrat';
  white-space: nowrap;
  height: 30px;
  padding-top: 10px;
}

.__landing-section-view-all:hover {
  border-bottom: 1px solid;
}

/* LandingChangePassword */

.__landing-change-password-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 400px;
}

.__landing-change-password-title {
  margin-bottom: 20px;
}

.__landing-change-password-field {
  margin-bottom: 20px;
}

.__landing-change-password-button {
  width: 50% !important;
  margin-top: 20px;
  height: 40px !important;
}

/* SearchBar */

.__landing-search-bar-parent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.__landing-search-bar {
  margin-left: 28px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color-inverse);
  border-radius: var(--basic-border-radius);
}

.__landing-search-bar-selected-school {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  /* border-radius: 5px 0 0 5px; */
  border-top-left-radius: var(--basic-border-radius);
  border-bottom-left-radius: var(--basic-border-radius);
  border: none !important;
  background-color: var(--background-primary-color);
  /* color: var(--font-primary-color); */
  color: var(--font-input-color);
  font-size: 18px;
}

.__landing-search-bar-schools {
  height: 52px;
  width: 300px;
}

.__landing-search-bar-date {
  width: 25%;
  height: 52px;
  width: 200px;
}

.__landing-search-bar-date .__textfield-parent {
  height: 100%;
}

.__landing-search-bar-type {
  height: 52px;
  width: 200px;
}

.__landing-search-bar-button {
  font-size: 18px;
  width: fit-content;
  height: 52px;
  padding-left: 10px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  background-color: var(--button-color);
  /* border-radius: 0px 5px 5px 0px; */
  border-top-right-radius: var(--basic-border-radius);
  border-bottom-right-radius: var(--basic-border-radius);
  transition: all .4s;
}

.__landing-search-bar-button:hover {
  filter: brightness(110%);
}

.__landing-search-bar-button-icon {
  color: var(--font-quaternary-color)!important;
}

.__landing-search-bar-button:hover .__landing-search-bar-button-icon {
  animation: shake .3s;

}

@keyframes shake {
  0% { transform: rotate(-10deg); }
  30% { transform: rotate(10deg); }
  60% { transform: rotate(-10deg); }
  100% { transform: rotate(10deg); }
}

.__landing-search-bar-button-text {
  margin-left: 10px;
  color: var(--font-quaternary-color);

}

.__landing-search-bar-divider {
  height: 52px;
  width: 1px;
  background-color: var(--ternary-color);
}

.__landing-search-bar-field {
  border: none !important;
  border-radius: 0px !important;
  background-color: var(--background-primary-color);
  font-size: 18px;
}

.__landing-search-bar-datepicker {
  padding: 0px !important;
}

.__landing-search-bar-select {
  border-top-left-radius: var(--basic-border-radius)!important;
  border-bottom-left-radius: var(--basic-border-radius)!important;
  /* border-radius: 5px 0px 0px 5px !important; */
}

/* SearchBar SP */

.__landing-search-bar-parent-sp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.__landing-search-bar-sp-content {
  width: 100%;
  margin: 13px;
}

.__landing-search-bar-sp-field-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--ternary-color);
  border-radius: var(--basic-border-radius);
  height: 40px;
  width: 100%;
  background-color: var(--background-secondary-color);
}

.__landing-search-bar-sp-field {
  color: var(--font-ternary-color);
  font-size: 18px;
  font-family: 'Montserrat';
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}

.__landing-search-bar-drawer {
  background-color: var(--background-homepage-color)!important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px 20px 20px;
}

.__landing-search-bar-drawer-title {
  font-family: 'Montserrat' !important;
}

.__landing-search-bar-drawer-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0px 20px 0px;
}

.__landing-search-bar-drawer-section-title {
  color: var(--font-primary-color);
  font-size: 18px;
  font-family: 'Montserrat';
  margin: 0px 0px 10px 0px;
}

.__landing-search-bar-panel {
  display: flex;
  align-items: center;
  border: 1px solid var(--ternary-color);
  border-radius: var(--basic-border-radius);
  height: 40px;
  width: 100%;
}

.__landing-search-bar-sp-button {
  margin: 20px 0px 20px 0px;
  font-size: 18px;
  width: 100%;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--button-color);
  border-radius: var(--basic-border-radius);
}


.__landing-about-logo-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  min-width: 200px;

}

@media only screen and (min-width: 901px) {
}

/* Smartphones */

@media only screen and (max-width: 900px) {
  .__landing-lesson-card-text-content .__landing-lesson-card-title-more {
    display: initial;
  
  }
  
  .__landing-lesson-card-text-content .__landing-newsfeed-card-description-more {
    display: block;
    color: white;
    font-weight: bold;
    opacity: 0;
    height: fit-content;
    max-height: 0px;
    transition: all 0.2s cubic-bezier(0.2, 0, 0.3, 1);
  }
  
  .__landing-lesson-card-image-container:hover .__landing-newsfeed-card-description-more {
    display: block;
    opacity: 1;
    max-height: 150px;
    margin-bottom: 0px;
    text-align: right;
  }

  .__landing-lesson-card-text-content .__landing-lesson-card-title-more  {
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.2, 0, 0.3, 1);
  }

  .__landing-lesson-card-image-container:hover .__landing-lesson-card-title-more {
    opacity: 0;
  }

  .__landing-lesson-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .__landing-lesson-card-image-container:hover .__landing-newsfeed-card-description{
    margin-bottom: 0px;
    text-align: left;
  }
  .__landing-search-bar-selected-school {
    border-radius: var(--basic-border-radius);
  }
  .__landing-container {
    padding: 5px;
  }

  .__landing-lesson-card-parent,
  .__landing-lesson-card-parent-4 {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .__landing-lesson-card-image {
    width: 300px;
  }

  .__landing-lesson-card-parent:nth-child(3n+1),
  .__landing-lesson-card-parent:nth-child(3n+3),
  .__landing-lesson-card-parent-4:nth-child(4n+1),
  .__landing-lesson-card-parent-4:nth-child(4n+2),
  .__landing-lesson-card-parent-4:nth-child(4n+3),
  .__landing-lesson-card-parent-4:nth-child(4n+4) {
    padding-left: 0px;
    padding-right: 13px;
  }

  .__landing-lesson-card-parent:first-child,
  .__landing-lesson-card-parent-4:first-child {
    padding-left: 0px;
  }

  .__landing-lesson-card-parent:last-child,
  .__landing-lesson-card-parent-4:last-child {
    padding-right: 0px;
  }
  .__landing-newsfeed-card-description {
    text-align: justify;
  }

  .__landing-newsfeed-card-parent {
    min-width: 250px;
    min-height: 155px;
  }

  .__landing-section-parent {
    margin: 20px 0px 40px 0px;
  }

  .__landing-about {
    padding: 20px 0px 20px 0px;
  }

  .__landing-about-logo {
    height: 100px;
    width: auto;
    margin-bottom: 10px;
  }

  .__landing-about-logo-container {
    min-width: none;

  }

  .__landing-about-text-container {
    flex-direction: column;
    display: block;
  }

  .__landing-section-content {
    flex-wrap: nowrap;
    overflow: auto;
  }

  .__landing-section-view-all {
    padding-top: 9px;
  }

  .__landing-search-bar-schools,
  .__landing-search-bar-date,
  .__landing-search-bar-type {
    width: 100%;
    height: 100%;
  }

  .__landing-search-bar-field {
    border-radius: var(--basic-border-radius)!important;
  }

  .__landing-search-bar-button {
    font-size: 16px;
    background-color: var(--button-color);
    height: 38px;
  }

  .__landing-search-bar-divider {
    height: 40px;
  }

  .__school-site-content {
    column-count: 1;
  }

  .__school-site-content-sitemap,
  .__school-site-content-sitemap-footer {
    column-count: 2;
    column-gap: 10px;
  }

  .__school-site-content-sitemap p,
  .__school-site-content-sitemap-footer p,
  .__avoid-break p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .__landing-change-password-parent {
    min-width: 95%;
  }
}
