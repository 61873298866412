/* SettingsCard */

.__settings-card-parent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.__settings-card-content {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.__settings-card-title {
    color: var(--font-primary-color);
    font-size: 16px;
    margin: 5px 5px 10px 0px;
}

.__settings-card-text {
    white-space: pre-wrap;
    color: var(--ternary-color);
    font-size: 14px;
    margin: 5px 5px 5px 0px;
}

.__settings-card-edit {
    height: max-content;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 14px;
    margin: 15px 10px 0px 5px;
}

.__settings-card-edit:hover {
    border-bottom: var(--primary-color) 1px solid;
}

.__settings-card-textfield {
    width: 100%;
    height: 100%;
    margin: 22px 0px 10px 0px;
}

.__settings-card-button-input {
    padding: 10px;
    height: 40px;
    width: fit-content;
}

.__settings-card-selectfield-container {
    margin: 22px 0px 10px 0px;
}

.__settings-card-datepicker-container {
    width: 200px;
    margin: 22px 0px 10px 0px;
}

.__settings-card-switch-container {
    display: flex;
    justify-content: space-between;
    margin: 22px 0px 10px 0px;
}

/* SettingsSection */

.__settings-section-item {
    margin: 5px;
}

.__settings-section-divider{
    height: 1px;
    background-color: var(--ternary-color);
    margin: auto 20px auto 20px;
    border: none;
}

.__settings-participants-expandable {
    height: 40px;
    font-size: 18px;
    background-color: var(--background-primary-color);
}